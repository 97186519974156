import axios from "axios";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import StandardButton from "../button/StandardButton";
import Partner from "./Partner";
import AlertModal from "../AlertModal";
import PartnerModal from "./PartnerModal";
import {displayErrorMessage, displayMessage} from "../../services/errorsTools";
import {useNavigate} from "react-router-dom";
import { hasAuthenticated } from "../../services/authTools";



function Partners() {
    const {user, setUser} = useContext(AuthContext);
    const [partners, setPartners] = useState([]);
    const [showPartnerModal, setShowPartnerModal] = useState(false);
    const [Message, setMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        setUser(hasAuthenticated());
        axios.get(
            `${process.env.REACT_APP_HOST}/api/partners`,
            {headers : {"Authorization" : `Bearer ${user.token}`}}
        )
        .then((res) => {
            const response = res.data;
            setPartners(response);
        })
        .catch(err => {
            displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
        });
    }, [])

    function handleCreate(productData) {
        if (hasAuthenticated()) {
            axios.post(
                `${process.env.REACT_APP_HOST}/api/partners`,
                productData,
                { headers : {"Authorization" : `Bearer ${user.token}`}}
            )
            .then(res => {
                partners.push(res.data);
                setPartners([...partners]);
                displayMessage(setShowAlert, "Le partenaire a bien été créé", setMessage);
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    }

    function handleUpdate(partnerData) {
        if (hasAuthenticated()) {
            axios.put(
                `${process.env.REACT_APP_HOST}/api/partners/${partnerData.get("partnerId")}`,
                partnerData,
                {headers : {"Authorization" : `Bearer ${user.token}`}}
            )
            .then(res => {
                const index = partners.findIndex((element) => element._id === res.data._id);
                partners.splice(index, 1, res.data);
                setPartners([...partners]);
                displayMessage(setShowAlert, "La modification du partnenaire a bien été effectuée", setMessage);
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    }

    function handleAddImage(formData) {
        if (hasAuthenticated()) {
            axios.put(
                `${process.env.REACT_APP_HOST}/api/partners/${formData.get("partnerId")}/photos`,
                formData,
                {headers : {"Authorization" : `Bearer ${user.token}`}}
            )
            .then(res => {
                const index = partners.findIndex((element) => element._id === res.data._id);
                partners.splice(index, 1, res.data);
                setPartners([...partners]);
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        }  else {
            setUser(null);
        }
    }

    function handleDeleteImage(partnerId, imageIndex) {
        if (hasAuthenticated()) {
            axios.delete(
                `${process.env.REACT_APP_HOST}/api/partners/${partnerId}/photos`,
                {
                    headers : {"Authorization" : `Bearer ${user.token}`},
                    data: {index : imageIndex, userId: user.userId}
                }
            )
            .then((res) => {
                const partnerIndex = partners.findIndex((element) => element._id === res.data._id)
                partners.splice(partnerIndex, 1, res.data);
                setPartners([...partners]);
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    }

    function handleDelete(partnerId) {
        if (hasAuthenticated()) {
            axios.delete(
                `${process.env.REACT_APP_HOST}/api/partners/${partnerId}`,
                {
                    headers : {"Authorization" : `Bearer ${user.token}`},
                    data: {userId: user.userId}
                }
            )
            .then((res) => {
                const partnerIndex = partners.findIndex((element) => element._id === res.data._id)
                partners.splice(partnerIndex, 1);
                setPartners([...partners]);
                displayMessage(setShowAlert, `Le partenaire ${res.data.name} a bien été supprimé`, setMessage);
    
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    }

    return (
        <div>
            <div className="bg"></div>
            <main className="pt-5">
                <StandardButton text="Ajouter un partenaire" onClick={() => setShowPartnerModal(true)}/>
                {showPartnerModal && 
                    <PartnerModal 
                    buttonOnClick={handleCreate}
                    setShowModal={setShowPartnerModal}
                    showModal={showPartnerModal}
                    buttonText="Créer un partenaire"
                    />
                }
                {partners.map((e) => 
                    <Partner 
                    handleDeleteImage={handleDeleteImage}
                    handleAddImage={handleAddImage}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                    partnerId={e._id}
                    key={e._id}
                    name={e.name}
                    partnerUrl={e.partnerUrl}
                    logo={e.imageUrl}
                    shootingImagesUrl={e.shootingImagesUrl}
                    />
                )}
                {showAlert &&
                     <AlertModal
                     className="text-success px-3 fw-bold"
                     showModal={showAlert}
                     setShowModal={setShowAlert}
                     alertText={Message}
                     />
                }
                {showErrorAlert &&
                    <AlertModal
                    className="text-danger px-3 fw-bold"
                    showModal={showErrorAlert}
                    setShowModal={setShowErrorAlert}
                    alertText={Message}
                    />
                }
            </main>
        </div>
    );
}

export default Partners;
