import StandardButton from "../button/StandardButton";
import { useState, useRef } from "react";
import {Modal, Form} from "react-bootstrap";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import DeleteAlert from "../DeleteAlert";

function PartnerImageModal(props) {
    const {user} = useContext(AuthContext);
    // useRef to set the choosen file
    const fileRef = useRef();
    // useState to set the post image
    const [image, setImage] = useState(props.image ? props.image : null);
    // useState to set and display post image error (file types)
    const [errorImage, setErrorImage] = useState(null);
    // useState to display the choosen image in modal
    const [showImage, setShowImage] = useState(image !== null);
    // useState to deleteImage in database
    const [deleteImage, setDeleteImage] = useState(false);
   

    let formData = new FormData();


    function handleForm() {  
        formData.append("partnerId", props.partnerId)  
        formData.append("userId", user.userId);
        formData.append("type", "partners");
        formData.append("images", fileRef.current.files[0]);
    }

    function handleFile(e) {
        const fileName = e.target.files[0].name;
        const splittedName = fileName.split('.');
        const extension = splittedName[splittedName.length - 1];
        if (['png', 'jpeg', 'jpg'].includes(extension)) {
            fileRef.current.files = e.target.files;
            setImage(URL.createObjectURL(e.target.files[0]));
            setShowImage(true);
            setErrorImage(null);
            setDeleteImage(false);
        } else {
            setErrorImage("Ce format de fichier n'est pas accepté");
            handleReset();
        }
    };

    // Function to delete the choosen file
    function handleReset() {
        fileRef.current.value = null;
        setShowImage(false);
        setImage(null);
        setDeleteImage(true);
    }

    return (
        <Modal 
        show={props.showModal} 
        aria-labelledby={props.headerText}
        onHide={() => props.setShowModal(false)}
        centered
        >
            <Modal.Header 
                className="border-0" 
                closeButton
            >
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    {showImage && 
                        <div className="text-center">
                            <img className="w-50 img-fluid mb-3" src={image} alt={`photo shooting de ${props.name}`} />
                        </div> 
                    }
                    {!props.image && 
                        <div>
                            <StandardButton
                                variant="black"
                                onClick={() => fileRef.current.click()}
                                text={image != null ? "Changer d'image" : "Ajouter une image"}
                            />
                            <Form.Control
                                name="image"
                                ref={fileRef}
                                onChange={handleFile}
                                multiple={false}
                                type="file"
                                hidden
                            />
                            <p className={errorImage ? "text-danger" : null}>{errorImage ? errorImage : "Fichiers acceptés : jpg, png, jpeg"}</p>  
                        </div>
                    }  
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="position-relative">
            {props.showAlert ?
                <DeleteAlert 
                onClose={() => {props.setShowAlert(false); props.setShowModal(false)}}
                text={`Etes-vous sur de vouloir supprimer cette image ?`}
                onClick={() => {props.handleDeleteImage(props.partnerId, props.index, user.userId); props.setShowModal(false)}}
                />
                : 
                <div className="d-flex justify-content-end w-100">
                    {!props.image && 
                        <StandardButton 
                            disabled={image == null}
                            variant="black"
                            text="Enregistrer l'image"
                            onClick ={() => {handleForm(); props.handleAddImage(formData); props.setShowModal(!props.showModal)}}
                        />
                    }   
                </div>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default PartnerImageModal;