import logo from "../../assets/logo_bombers_white.png";
import {Navbar, Nav, Button, Offcanvas} from "react-bootstrap";
import {Link} from "react-router-dom";
import "./Header.scss";
import AuthContext from "../../context/AuthContext";
import { useContext, useState } from "react";
import { hasAuthenticated } from "../../services/authTools";
import { removeCookie } from "../../services/cookiesTools";


function AdminHeader() {

    const {user, setUser} = useContext(AuthContext);
    const [showMenu, setShowMenu] = useState(false);

    function handleLogout() {
        removeCookie("admin");
        setUser(hasAuthenticated());
    }

    return (
        <header>
            <Navbar collapseOnSelect="true" expand="false" bg={user ? "black" : "transparent"} variant="dark" className="px-3 py-3 w-100">
                <img src={logo} alt="Logo Bombers Original" className="header-img m-auto"/>
                {user && 
                    <Navbar.Toggle 
                        onClick={() => setShowMenu(true)}
                        className="header-nav"
                        aria-controls="navBar" 
                        id="dropdown-autoclose-true"
                    /> 
                }
                {user && 
                    <Navbar.Offcanvas 
                        show={showMenu}
                        onHide={() => setShowMenu(false)}
                        placement="start"
                        id="navBar"
                        className="bg-black text-white"
                    >
                        <Offcanvas.Header className="justify-content-end" closeButton>
                        </Offcanvas.Header>
                        <Nav>
                            <Nav.Link  
                                as={Link} 
                                onClick={() => setShowMenu(false)}
                                to="/admin/products" 
                                href="/admin/products" 
                                className="admin-link text-white"
                            >
                                    Produits
                            </Nav.Link>
                            <Nav.Link  
                                as={Link} 
                                onClick={() => setShowMenu(false)}
                                to="/admin/partners" 
                                href="/admin/partners" 
                                className="admin-link text-white"
                            >
                                    Partenariats
                            </Nav.Link>
                            <Nav.Link  
                                as={Link} 
                                onClick={() => setShowMenu(false)}
                                to="/admin/accounts" 
                                href="/admin/accounts" 
                                className="admin-link text-white"
                            >
                                    {user.isSuperAdmin ? "Liste des comptes" : "Votre compte"}
                            </Nav.Link>
                            <Nav.Link>
                                <Button
                                    className="mt-2 btn btn-primary text-white"
                                    onClick={() => {handleLogout(); setShowMenu(false)}}
                                >
                                    Deconnexion
                                </Button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Offcanvas>
                }  
            </Navbar>
        </header>   
    )
}

export default AdminHeader;