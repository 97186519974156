export const contactTranslation = {
    headTitle : {
        fr : "Bombers Original Partenariats - Contact",
        en : "Bombers Original Partnerships - Contact"
    },
    headDescription : {
        fr : "Vous pouvez nous contacter via ce formulaire afin devenir l'un des futurs partenaires de Bombers Original Partenariats. Nos équipes reviendront vers vous au plus vite afin de vous proposer un Bombers Original personnalisé aux couleurs de votre entreprise et/ou votre club", 
        en : "You can contact us by this from to become the next partners of Bombers Original partnerships. Our team will be back in touch with you to create a customized Bombers Original in the colours of your club or company"
    },
    mainText : {
        fr : "Vous souhaitez plus d'informations sur nos partenariats ?",
        en : "Would you like further information about our partnerships ?"
    },
    products : {
        fr : "Produits",
        en : "Products"
    },
    firstName : {
        fr : "Prénom",
        en : "First name"
    },
    lastName : {
        fr : "Nom",
        en : "Last name"
    },
    email : {
        fr : "E-mail",
        en : "Email"
    },
    company : {
        fr : "Société",
        en : "Company"
    },
    message : {
        fr : "Votre message",
        en : "Your message"
    },
    buttonText : {
        fr : "Envoyer",
        en : "Send"
    },
    requestText : {
        fr : "Retour à la page d'accueil",
        en : "Go back to home page"
    },
    sendMessage1 : {
        fr : "Nous vous remercions pour votre demande de partenariat !",
        en : "Thank's for your partnership request"
    },
    sendMessage2 : {
        fr : "Votre message a bien été envoyé, nous vous répondrons via l'email fourni au plus vite !",
        en : "Your message has been sent, we we'll get back to you by email as soon as possible"
    }
}
