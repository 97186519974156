import { contactTranslation } from "./contactTranslation"
import {headerTranslation} from "./headerTranslation" 
import {homeTranslation} from "./homeTranslation"
import { productTranslation } from "./productTranslation"

export const translate = {
    header : headerTranslation,
    home : homeTranslation,
    contact : contactTranslation,
    products : {
        headTitle : {
            fr : "Bombers Original Partenariats - Produits",
            en : "Bombers Original Partnerships - Products"
        },
        headDescription : {
            fr : "Bombers Original Partenariats vous présente nos nombreux modèles de Bombers Original personnalisables aux couleurs de votre entreprise et/ou votre club.", 
            en : "Bombers Original Partnerships propose all these customizable Bombers Original in the colors of your Company or Club."
        },
        productsText : {
            fr : "Nos produits personnalisables",
            en : "Our customizable products"
        }
    },
    product : productTranslation,
    gallery : {
        buttonText : {
            fr : "Voir toutes les photos", 
            en : "See all pictures"
        },
        partnerText : {
            fr : "Visitez la boutique du partenaire", 
            en : "Take a look at the partner's shop"
        },
        here : {
            fr : "ici", 
            en : "here"
        }
    },
    partnerGallery : {
        headTitle : {
            fr : "Bombers Original Partenariats - Galerie des partenaires",
            en : "Bombers Original Partnerships - Partners Gallery"
        },
        headDescription : {
            fr : "Vous trouverez ici la galerie présentant nos nombreuses collaborations x Bombers Original Partenariats", 
            en : "You will find here the gallery with all our partnerships x Bombers Original Partnerships"
        },
        buttonText : {
            fr : "Retour à l'accueil",
            en : "Go back to home page"
        }
    },
    footer : {
        footerText1 : {
            fr : "Bombers Original",
            en : "Bombers Original"
        },
        footerText2 : {
            fr : "Partenariats",
            en : "Partnerships"
        },
    },
    errorPage : {
        whoops : {
            fr : "Oups...",
            en : "Whoops..."
        },
        errorText1 : {
            fr : "Une erreur serveur est survenue.",
            en : "An error occured"
        }, 
        errorText2 : {
            fr : "Veuillez réessayer ultérieurement.",
            en : "Please try again later"
        },
        buttonText : {
            fr : "Retour à la page d'accueil",
            en : "Go back to home page"
        }
    },
    notFoundPage : {
        errorText1 : {
            fr : "La page est introuvable",
            en : "Page not found"
        }, 
        errorText2 : {
            fr : "La page recherchée est introuvable",
            en : "The page that you're looking for doesn't exist"
        },
        buttonText : {
            fr : "Retour à la page d'accueil",
            en : "Go back to home page"
        }
    },
}
