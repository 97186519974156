import StandardButton from "../button/StandardButton";
import { useState, useRef } from "react";
import {Modal, Form} from "react-bootstrap";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

function AdminAccountModal(props) {
    const {user} = useContext(AuthContext);
    const [adminData, setAdminData] = useState({
        accountId : props.accountId,
        userId : user.userId,
        email : props.email ? props.email : "",
        firstName : props.firstName ? props.firstName : "",
        lastName : props.lastName ? props.lastName : ""
    });

    const emailRegex = /^([a-zA-Z0-9\.-_]+)@([a-zA-Z0-9-_]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;

    function handleChange({currentTarget}) {
        const {name, value} = currentTarget;
        adminData[name] = value;
        setAdminData({...adminData});
    }

    return (
        <Modal 
        show={props.showModal} 
        aria-labelledby={props.name}
        onHide={() => props.setShowModal(false)}
        centered
        >
            <Modal.Header className="border-0" closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form.Group
                controlId="formfirstName" >
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control 
                    onChange={(e) => handleChange(e)} 
                    className="post-input rounded-3 mb-2" 
                    name="firstName"
                    value={adminData.firstName}
                    />           
                </Form.Group>
                <Form.Group
                controlId="formlasttName" >
                    <Form.Label>Nom</Form.Label>
                    <Form.Control 
                    onChange={(e) => handleChange(e)} 
                    className="post-input rounded-3 mb-2" 
                    name="lastName"
                    value={adminData.lastName}
                    />           
                </Form.Group>
                <Form.Group
                controlId="E-mail" >
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control 
                    onChange={(e) => handleChange(e)} 
                    className="post-input rounded-3 mb-2" 
                    name="email"
                    value={adminData.email}
                    />           
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="position-relative">
                <div className="d-flex justify-content-end w-100">
                    <StandardButton 
                    disabled={!emailRegex.test(adminData.email) || (adminData.firstName.length === 0 || adminData.lastName.length === 0)}
                    variant="black"
                    text={props.buttonText}
                    onClick={() => {props.handleUpdateAdminData(adminData); props.setShowModal(false)}}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default AdminAccountModal;