import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {Navbar, Nav, Button, Offcanvas} from "react-bootstrap";
import {Link} from "react-router-dom";
import logo from "../../assets/logo_bombers_white.png";
import SettingsContext from "../../context/SettingsContext";
import { getCookie, removeCookie, setCookie } from "../../services/cookiesTools";
import { getSettings } from "../../services/settingsTools";
import { translate } from "../../services/translation/translate";
import "./Header.scss";


function Header() {

    const {settings, setSettings} = useContext(SettingsContext);
    const [showMenu, setShowMenu] = useState(false);
    const [storeSettings, setStoreSettings] = useState({
        language : ""
    });

    function handleLanguage({currentTarget}) {
        const {name, value} = currentTarget;
        setStoreSettings({...storeSettings, [name] : value});
    }

    function handleSettings() {
        if (getCookie("settings")) {
            removeCookie("settings");
        }
        setCookie("settings", storeSettings, 1);
        setSettings(getSettings());
    }

    useEffect(() => {
        handleSettings();
    }, [storeSettings]);

    return (
        <header className="fixed-top">
            <Navbar collapseOnSelect="true" expand="false" bg="black" variant="dark" className="px-3 py-3 w-100">
                <Link to="/home" className="m-auto"><img src={logo} alt={translate.header.alt[settings.language]} className="header-img"/></Link>
                <Navbar.Toggle 
                    onClick={() => setShowMenu(true)}
                    className="header-nav text-primary"
                    variant="primary"
                    aria-controls="navBar" 
                    id="dropdown-autoclose-true"
                /> 

                <Navbar.Offcanvas 
                    show={showMenu}
                    onHide={() => setShowMenu(false)}
                    placement="start"
                    id="navBar"
                    className="bg-black text-white"
                >
                    <Offcanvas.Header className="justify-content-end" closeButton>
                    </Offcanvas.Header>
                    <Nav>
                    <div className="d-flex align-items-center justify-content-start">
                            <p className="pe-5">{translate.header.language[settings.language]}</p>
                            <div className="row gap-2">
                                <div className="col-auto p-0">
                                    <Button 
                                        variant="black"
                                        className={settings.language === "fr" ? "btn-circle__fr text-white" : "btn-circle text-white"}
                                        onClick={(e) => handleLanguage(e)}
                                        name="language"
                                        value="fr"
                                    >
                                        FR
                                    </Button>
                                </div>
                                <div className="col-auto p-0">
                                    <Button 
                                        variant="black"
                                        className={settings.language === "en" ? "btn-circle__en text-white" : "btn-circle text-white"}
                                        onClick={(e) => handleLanguage(e)}
                                        name="language"
                                        value="en"
                                    >
                                        EN
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <Nav.Link  
                            as={Link} 
                            onClick={() => setShowMenu(false)}
                            to="/home" 
                            href="/home" 
                            className="pt-4 admin-link text-white"
                        >
                                {translate.header.home[settings.language]}
                        </Nav.Link>
                        <Nav.Link  
                            as={Link} 
                            onClick={() => setShowMenu(false)}
                            to="/products" 
                            href="/products" 
                            className="admin-link text-white"
                        >
                            {translate.header.products[settings.language]}
                        </Nav.Link>
                        <Nav.Link  
                            as={Link} 
                            onClick={() => setShowMenu(false)}
                            to="/gallery" 
                            href="/gallery" 
                            className="admin-link text-white"
                        >
                            {translate.header.gallery[settings.language]}
                        </Nav.Link>
                        <div className="pt-3">
                            <Button
                                onClick={() => setShowMenu(false)}
                                variant="outline-primary"
                                className="mt-2 text-white"
                                as={Link}
                                to="/contact" 
                                href="/contact"
                            >
                                {translate.header.contact[settings.language]}
                            </Button>
                        </div>
                    </Nav>
                </Navbar.Offcanvas> 
            </Navbar>
        </header>   
    )
}

export default Header;