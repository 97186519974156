import "./scss/custom.scss";
import React from "react";
import AuthContext from "./context/AuthContext";
import { Routes, Route} from "react-router-dom";
import LoginForm from "./components/authForm/LoginForm";
import Partners from "./components/partners/Partners";
import Products from "./components/products/Products";
import {useState} from "react";
import {hasAuthenticated} from "./services/authTools";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import AdminAccounts from "./components/adminAccounts/AdminAccounts";
import SignUpForm from "./components/authForm/SignUpForm";
import ServerErrorPage from "./components/pages/errorPages/ServerErrorPage";
import NotFoundPage from "./components/pages/errorPages/NotFoundPage";
import AdminHeader from "./components/header/AdminHeader";


function Admin() {

    const [user, setUser] = useState(hasAuthenticated());

    return(
        <AuthContext.Provider value ={{user, setUser}}>
            <AdminHeader/>
            <Routes>
                <Route exact path = "/login" element={<LoginForm/>}/>
                <Route element={<AuthenticatedRoute/>}>
                    <Route exact path = "/products" element={<Products/>}/>
                    <Route exact path = "/partners" element={<Partners/>}/>
                    <Route exact path = "/accounts" element={<AdminAccounts/>}/>
                </Route>
                <Route exact path = "/confirmation/*" element={<SignUpForm/>}/>
                <Route exact path = "/error-500" element={<ServerErrorPage />}/>
                <Route exact path = "/*" element={<NotFoundPage/>}/>
            </Routes>
        </AuthContext.Provider>
    )
}

export default Admin;