import StandardButton from "../button/StandardButton";
import { useState, useRef } from "react";
import {Modal, Form} from "react-bootstrap";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import DeleteAlert from "../DeleteAlert";
import { checkLanguagesEntries } from "../../services/languagesTools";

function ProductColorModal(props) {
    const {user} = useContext(AuthContext);
    // useRef to set the choosen file
    const fileRef = useRef();
    // useState to set the post image
    const [image, setImage] = useState(props.image ? props.image : null);
    // useState to set and display post image error (file types)
    const [errorImage, setErrorImage] = useState(null);
    // useState to display the choosen image in modal
    const [showImage, setShowImage] = useState(image !== null);
    // useState to set the color object
    const [color, setColor] = useState(props.color ? {...props.color} : {});

    const [showAlert, setShowAlert] = useState(false)

    let formData = new FormData();

    function handleForm() {  
        formData.append("colorId", props.colorId)
        formData.append("productId", props.productId)  
        formData.append("userId", user.userId);
        formData.append("type", "products");
        formData.append("images", fileRef.current.files[0]);
        formData.append("color", JSON.stringify(color));
    }

    function handleFile(e) {
        const fileName = e.target.files[0].name;
        const splittedName = fileName.split('.');
        const extension = splittedName[splittedName.length - 1];
        if (['png'].includes(extension)) {
            fileRef.current.files = e.target.files;
            setImage(URL.createObjectURL(e.target.files[0]));
            setShowImage(true);
            setErrorImage(null);
        } else {
            setErrorImage("Ce format de fichier n'est pas accepté");
            handleReset();
        }
    };

    function checkColorEntries(color, image) {
        if (!checkLanguagesEntries(color) || image == null) {
            return false;
        }
        return true;
    }

    // Function to delete the choosen file
    function handleReset() {
        fileRef.current.value = null;
        setShowImage(false);
        setImage(null);
    }

    function handleColor({currentTarget}) {
        const {name, value} = currentTarget;
        color[name] = value;
        setColor({...color});
    }

    return (
        <Modal  
        show={props.showModal} 
        aria-labelledby={props.headerText}
        onHide={() => props.setShowModal(false)}
        centered
        >
            <Modal.Header className="border-0" closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formColorFr" >
                    <Form.Label>Couleur en français</Form.Label>
                    <Form.Control 
                    onChange={handleColor} 
                    className="post-input rounded-3 mb-2" 
                    name="fr"
                    placeholder="Couleur du produit"
                    value={color.fr}
                    />           
                </Form.Group>
                <Form.Group controlId="formColorEn" >
                    <Form.Label>Couleur en Anglais</Form.Label>
                    <Form.Control 
                        onChange={handleColor} 
                        className="post-input rounded-3 mb-2" 
                        name="en"
                        placeholder="Couleur du produit"
                        value={color.en}
                    />           
                </Form.Group>
                <Form.Group className="mb-3">
                    {showImage && 
                        <div className="text-center">
                            <img className="w-50 img-fluid mb-3" src={image} alt="" />
                        </div> 
                    }
                    <StandardButton
                        variant="black"
                        onClick={() => fileRef.current.click()}
                        text={image != null ? "Changer d'image" : "Ajouter une image"}
                    />
                    <Form.Control
                        name="image"
                        ref={fileRef}
                        onChange={handleFile}
                        multiple={false}
                        type="file"
                        hidden
                    />
                    <p className={errorImage ? "text-danger" : null}>{errorImage ? errorImage : "Fichiers acceptés : png"}</p>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="position-relative">
                {showAlert ? 
                    <DeleteAlert 
                        onClose={() => setShowAlert(false)}
                        text={`Etes-vous sur de vouloir supprimer le produit ${props.productName} ${props.color.fr} ?`}
                        onClick={() => props.handleDeleteColor(props.productId, props.colorId)}
                    />
                : 
                    <div className={props.image ? "d-flex justify-content-between w-100" : "d-flex justify-content-end w-100"}>
                        {props.image && 
                            <StandardButton 
                                variant="danger" 
                                text="Supprimer" 
                                onClick={() => setShowAlert(true)}
                            />
                        }
                        <StandardButton
                            disabled={!checkColorEntries(color, image)}
                            variant="black"
                            text={props.buttonText} 
                            onClick ={() => {handleForm(); props.buttonOnClick(formData); props.setShowModal(false)}}
                        />
                    </div>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default ProductColorModal;