import { useState, useContext, useEffect } from "react";
import {Form, Button} from "react-bootstrap";
import "./LoginForm.scss";
import {CgDanger} from "react-icons/cg";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { hasAuthenticated } from "../../services/authTools";
import { setCookie } from "../../services/cookiesTools";
import { displayError } from "../../services/errorsTools";
import {AiOutlineEyeInvisible, AiOutlineEye} from "react-icons/ai";


function LoginForm() {
    const [showPassword, setShowPassword] = useState(false);
    const {user, setUser} = useContext(AuthContext);
    let navigate = useNavigate();
    const emailRegex = /^([a-zA-Z0-9\.-_]+)@([a-zA-Z0-9-_]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;

    const [credentials, setCredentials] = useState({
        email : "",
        password : ""
    })

    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);


    useEffect(() => {
        if(user) {
           navigate('/admin/products', {replace: true})
        }
     }, [])

    function handleChange({currentTarget}) {
        const {name, value} = currentTarget;
        setCredentials({...credentials, [name] : value});
    }


    function handleForm(e) {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_HOST}/api/admin/login`,{
            ...credentials
        })
        .then((res) => {
            const dataUser = res.data;
            setCookie("admin", dataUser, 1);
            setUser(hasAuthenticated());
            navigate("/admin/products", {replace: true})
        })
        .catch(err => {
            const error = err.response.data.error;
            const errorMessage = displayError(err, navigate);
            setPasswordError(error.includes("PASSWORD") ? errorMessage : null);
            setEmailError(error.includes("EMAIL") ? errorMessage : null);
        });
    }

    return (
        <div className="bg-auth vh-100">
            <main className="vh-100 d-flex m-auto container col-sm-12 col-md-8 col-lg-5 align-items-center justify-content-center">
                <div className="bg-light p-3 rounded bg-opacity-75">
                    <h1 className="fs-2 fw-bolder text py-3 text-center text-tertiary">Connexion Bombers Partenariats Administrateur</h1>
                    <Form onSubmit={handleForm}>
                        <Form.Group id="email">
                            <Form.Label className="fw-bolder">E-mail</Form.Label>
                            <Form.Control
                                className={emailError != null ? "login-control bg-transparent rounded-0 border-bottom border-danger p-0" : "login-control rounded-0 bg-transparent border-bottom border-black p-0 mb-2"}
                                onChange={(e) =>handleChange(e)}
                                value={credentials.email}
                                id="email" 
                                name="email" 
                                type="email" 
                                autoComplete="on"
                                required
                            />
                            {emailError != null && 
                                <div className="d-flex mt-2 text-white">
                                    <span>
                                        <CgDanger color="red"/>
                                    </span>
                                        <p className="px-2 text-danger fw-bolder">{emailError}</p>
                                </div>
                            }
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label className="fw-bolder">Mot de passe</Form.Label>
                            <div className="position-relative">
                                <Form.Control 
                                    className={passwordError != null ? "login-control bg-transparent rounded-0 border-bottom border-danger pe-5 ps-0 py-0" : "login-control rounded-0 bg-transparent border-bottom border-black pe-5 ps-0 py-0 mb-2"}
                                    onChange={(e) => {handleChange(e);}}
                                    value={credentials.password}
                                    autoComplete="current-password"
                                    id="password" 
                                    name="password" 
                                    type={showPassword ? "text" : "password"}

                                    required
                                />
                                {showPassword ? <AiOutlineEye size={22} className="password-icon" onClick={() => setShowPassword(false)}/>: <AiOutlineEyeInvisible size={22} className="password-icon" onClick={() => setShowPassword(true)}/> }
                            </div>
                            {passwordError != null && 
                            <div className="d-flex mt-2 text-white">
                                <span>
                                    <CgDanger color="red"/>
                                </span>
                                <p className="px-2 text-danger fw-bolder">{passwordError}</p>
                            </div>
                            }
                        </Form.Group>
                        <div className="text-center p-3">
                            <Button
                            disabled={!emailRegex.test(credentials.email)}
                            type="submit"
                            variant="primary"
                            className="fs-5 text-white fw-bold border-primary"
                            >Connexion</Button>
                        </div>
                    </Form>
                </div>
            </main> 
        </div>
        
    )
}

export default LoginForm;