import {Alert, Button} from "react-bootstrap";

function DeleteAlert(props) {
    return (
            <Alert 
                className="text-center"
                variant="silver" 
                onClose={props.onClose} 
                dismissible
            >
                <p>{props.text}</p>
                <div className="d-flex justify-content-center">
                    <Button 
                        className="text-white" 
                        onClick={props.onClick}
                    >
                        Confirmer
                    </Button>
                </div>
            </Alert>
    );

}

export default DeleteAlert;