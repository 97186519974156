import "./scss/custom.scss";
import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Customer from "./customer";
import Admin from "./admin";


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='admin/*' element={<Admin/>} />
        <Route path='/*' element={<Customer/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
