import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import StandardButton from "../button/StandardButton";


function InvitationModal(props) {

    const emailRegex = /^([a-zA-Z0-9\.-_]+)@([a-zA-Z0-9-_]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
    const [email, setEmail] = useState("");

    return (
        <Modal  
        show={props.showModal} 
        aria-labelledby={props.headerText}
        onHide={() => props.setShowModal(false)}
        centered
        >
            <Modal.Header className="border-0" closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formEmail" >
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            onChange={(e) => setEmail(e.target.value)} 
                            type="email"
                            className="rounded-3 mb-2" 
                            name="oldPassword"
                            autoComplete="on"
                            value={email}
                        />           
                    </Form.Group>
                </Form>
                <p style={{fontSize: ".80rem"}}>L'invitation de ce collaborateur perdurera une heure</p>
            </Modal.Body>
            <Modal.Footer className="position-relative">
                <StandardButton
                    onClick={() => {props.handleSendInvitation(email); props.setShowModal(false)}}
                    disabled={!emailRegex.test(email)}
                    variant="black"
                    text="Envoyer l'invitation" 
                />
            </Modal.Footer>
        </Modal>
    )
}

export default InvitationModal;