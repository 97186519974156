import {Button} from "react-bootstrap";

function StandardButton(props) {
    return (
        <div className="text-center p-1">
        <Button
        variant={props.variant}
        onClick={props.onClick}
        disabled={props.disabled}
        type="button"
        className="text-white fw-bold"
        >
            {props.text}
        </Button>
        </div>
    )
}

export default StandardButton;