import {BsThreeDots} from "react-icons/bs";
import Carousel from "react-multi-carousel";
import { Dropdown } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { useContext, useState } from "react";
import PartnerCard from "./PartnerCard";
import DeleteAlert from "../DeleteAlert";
import PartnerModal from "./PartnerModal";
import "./Partner.scss";
import PartnerImageModal from "./PartnerImageModal";
import AuthContext from "../../context/AuthContext";


function Partner(props) {
    const {user} = useContext(AuthContext);
    const [showPartnerModal, setShowPartnerModal] = useState(false);
    const [showPartnerImageModal, setShowPartnerImageModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);



    const responsiveRules = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 998 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 998, min: 587 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 587, min: 0 },
            items: 2
        }
    };

    return (
        <article className="container partner-container rounded my-3 px-0 position-relative">
            {showPartnerModal && 
                <PartnerModal 
                    buttonOnClick={props.handleUpdate}
                    buttonText="Enregistrer les modifications"
                    showModal={showPartnerModal}
                    setShowModal={setShowPartnerModal}
                    partnerId={props.partnerId}
                    name={props.name}
                    partnerUrl={props.partnerUrl}
                    image={props.logo}
                />
            }
            <div className="bg-black rounded-top text-white p-2">
                <h2 className="fs-4 text-uppercase shadow-sm text-center fw-bold m-auto w-75 px-5 py-2">{props.name}</h2>
                <Dropdown
                    className="partner-container__toggle-container"
                    align="end"
                >
                    <Dropdown.Toggle 
                        className="partner-container__toggle-container__toggle bg-transparent shadow-none border-light p-0 m-0"
                    >
                        <BsThreeDots size={24} color="white" aria-label="ouvrir le menu à developper"/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-partner-menu">
                        <Dropdown.Item 
                            className="dropdown-partner-menu__item"
                            onClick={() => setShowPartnerModal(true)}
                        >
                            Modifier
                        </Dropdown.Item>
                        <Dropdown.Item 
                            className="dropdown-partner-menu__item"
                            onClick={() => setShowAlert(true)}
                        >
                            Supprimer
                        </Dropdown.Item>
                        <Dropdown.Item 
                            className="dropdown-partner-menu__item"
                            onClick={() => setShowPartnerImageModal(true)}
                        >
                            Ajouter une image
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="p-3 text-center fw-bolder position-relative m-auto">
                {showPartnerImageModal && 
                <PartnerImageModal
                    key="AddImage"
                    name={props.name}
                    show={showPartnerImageModal}
                    partnerId={props.partnerId}
                    handleAddImage={props.handleAddImage}
                    showModal={showPartnerImageModal}
                    setShowModal={setShowPartnerImageModal}
                />
                }
                {showAlert &&
                    <DeleteAlert 
                        show={showAlert}
                        onClose={() => setShowAlert(false)}
                        text={`Etes-vous sur de vouloir supprimer le partenaire ${props.name} ?`}
                        onClick={() => props.handleDelete(props.partnerId, user.userId)}
                    />
                }
                <div className="pb-2 text-center pb-4">
                    <h3 className="fw-bolder fs-6 ">Url du partenaire</h3>
                    {props.partnerUrl ?
                        <a href={props.partnerUrl}><u>{props.partnerUrl}</u></a>
                    :
                        <p>Aucun lien renseigné</p>
                    }
                </div>
                <img className="partner-logo img-fluid w-25" alt={`logo ${props.name}`} src={props.logo}/>
            </div>
            <Carousel 
                responsive={responsiveRules} 
                className={props.shootingImagesUrl.length === 0 ? "m-2 align-items-center justify-content-center" : "m-2 align-items-center"}
            >
                {props.shootingImagesUrl.map((e, index) => 
                <PartnerCard 
                    name={props.name}
                    partnerId={props.partnerId}
                    handleDeleteImage={props.handleDeleteImage}
                    key={index}
                    index={index}
                    image={e}
                />
                )}
            </Carousel>
        </article>
    )
}

export default Partner;