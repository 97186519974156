import StandardButton from "../button/StandardButton";
import { useState, useRef } from "react";
import {Modal, Form} from "react-bootstrap";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

function PartnerModal(props) {
    const {user} = useContext(AuthContext);
    // useRef to set the choosen file
    const fileRef = useRef();
    // useState to set the post image
    const [image, setImage] = useState(props.image ? props.image : null);
    // useState to set and display post image error (file types)
    const [errorImage, setErrorImage] = useState(null);
    // useState to display the choosen image in modal
    const [showImage, setShowImage] = useState(image !== null);
    // useState to display the partner url
    const [partnerUrl, setPartnerUrl] = useState(props.partnerUrl ? props.partnerUrl : "");
    // useState to set the post textContent
    const [name, setName] = useState(props.name ? props.name : "");


    let formData = new FormData();


    function handleForm() {  
        formData.append("partnerId", props.partnerId)  
        formData.append("userId", user.userId);
        formData.append("type", "partners");
        formData.append("images", fileRef.current.files[0]);
        formData.append("name", name);
        formData.append("partnerUrl", partnerUrl);
    }

    function handleFile(e) {
        const fileName = e.target.files[0].name;
        const splittedName = fileName.split('.');
        const extension = splittedName[splittedName.length - 1];
        if (['png', 'jpeg', 'jpg'].includes(extension)) {
            fileRef.current.files = e.target.files;
            setImage(URL.createObjectURL(e.target.files[0]));
            setShowImage(true);
            setErrorImage(null);
        } else {
            setErrorImage("Ce format de fichier n'est pas accepté");
            handleReset();
        }
    };

    // Function to delete the choosen file
    function handleReset() {
        fileRef.current.value = null;
        setShowImage(false);
        setImage(null);
    }

    function checkPartnerEntries(name, image) {
        if (name.length === 0 || image == null) {
            return false;
        }
        return true;
    }

    return (
        <Modal 
        show={props.showModal} 
        aria-labelledby={props.name}
        onHide={() => props.setShowModal(false)}
        centered
        >
            <Modal.Header className="border-0" closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form.Group
                controlId="formName" >
                    <Form.Label>Nom du partenaire</Form.Label>
                    <Form.Control 
                    onChange={(e) => setName(e.target.value)} 
                    className="post-input rounded-3 mb-2" 
                    name="Name"
                    value={name}
                    />           
                </Form.Group>
                <Form.Group
                controlId="formPartnerUrl" >
                    <Form.Label>Lien du partenaire</Form.Label>
                    <Form.Control 
                    onChange={(e) => setPartnerUrl(e.target.value)} 
                    className="post-input rounded-3 mb-2" 
                    name="partnerUrl"
                    value={partnerUrl}
                    />           
                </Form.Group>
                <Form.Group 
                className="mb-3"
                >
                    {showImage &&
                        <div className="text-center">
                            <img className="w-50 img-fluid mb-3" src={image} alt="" />
                        </div> 
                    }
                    <StandardButton
                    variant="black"
                        onClick={() => fileRef.current.click()}
                        text={image != null ? "Changer le logo" : "Ajouter le logo"}
                    />
                    <Form.Control
                        name="image"
                        ref={fileRef}
                        onChange={handleFile}
                        multiple={false}
                        type="file"
                        hidden
                    />
                    <p>{errorImage ? errorImage : "Fichiers acceptés : jpg, png, jpeg"}</p>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="position-relative">
                <div className="d-flex justify-content-end w-100">
                    <StandardButton 
                    disabled={!checkPartnerEntries(name, image)}
                    variant="black"
                    text={props.buttonText}
                    onClick={() => {handleForm(); props.buttonOnClick(formData); props.setShowModal(false)}}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default PartnerModal;