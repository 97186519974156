import axios from "axios";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import Product from "./Product";
import StandardButton from "../button/StandardButton";
import ProductModal from "./ProductModal";
import { hasAuthenticated } from "../../services/authTools";
import AlertModal from "../AlertModal";
import {displayErrorMessage, displayMessage} from "../../services/errorsTools";
import {useNavigate} from "react-router-dom";

function Products() {

    const [showModal, setShowModal] = useState(false);
    const {user, setUser} = useContext(AuthContext);
    const [products, setProducts] = useState([]);
    const [Message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        setUser(hasAuthenticated());
        axios.get(`${process.env.REACT_APP_HOST}/api/products`,
         {headers: {"Authorization" : `Bearer ${user.token}`}}
        )
        .then((res) => {
            const response = res.data;
            setProducts(response);
        })
        .catch(err => {
            displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
        });  
    }, [])

    function handleCreate(productData) {
        if (hasAuthenticated) {
            axios.post(
                `${process.env.REACT_APP_HOST}/api/products`,
                productData,
                { headers : {"Authorization" : `Bearer ${user.token}`}}
            )
            .then(res => {
                products.push(res.data);
                setProducts([...products]);
                displayMessage(setShowAlert, "Le produit à bien été créé", setMessage);
    
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    }

    function handleUpdate(productData) {
        if (hasAuthenticated()) {
            axios.put(
                `${process.env.REACT_APP_HOST}/api/products/${productData.productId}`,
                productData,
                {headers : {"Authorization" : `Bearer ${user.token}`}}
            )
            .then(res => {
                const index = products.findIndex((element) => element._id === res.data._id);
                products.splice(index, 1, res.data);
                setProducts([...products]);
                displayMessage(setShowAlert, "Le produit a bien été modifié", setMessage);

            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        }
        else {
            setUser(null);
        }
    }

    function handleDelete(productId) {
        if (hasAuthenticated()) {
            axios.delete(
                `${process.env.REACT_APP_HOST}/api/products/${productId}`,
                {headers : {"Authorization" : `Bearer ${user.token}`},
                data : {userId : user.userId}},   
            )
            .then(res => {
                const productIndex = products.findIndex((element) => element._id === res.data._id)
                products.splice(productIndex, 1);
                setProducts([...products]);
                displayMessage(setShowAlert, "Le produit a bien été supprimée", setMessage);
    
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    }

    function handleColor(formData) {
        if (hasAuthenticated()) {
            axios.post(
                `${process.env.REACT_APP_HOST}/api/products/${formData.get("productId")}/colors`, 
                formData,
                {headers : {"Authorization" : `Bearer ${user.token}`}}
            )
            .then(res => {
                const productIndex = products.findIndex((element) => element._id === res.data._id);
                products.splice(productIndex, 1, res.data);
                setProducts([...products]);
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    }

    function handleUpdateColor(formData) {
        if (hasAuthenticated()) {
            axios.put(
                `${process.env.REACT_APP_HOST}/api/products/${formData.get("productId")}/colors/${formData.get("colorId")}`,
                formData,
                {headers : {"Authorization" : `Bearer ${user.token}`}},
                )
                .then(res => {
                    const productIndex = products.findIndex((element) => element._id === res.data._id);
                    products.splice(productIndex, 1, res.data);
                    setProducts([...products]);
                    displayMessage(setShowAlert, "L'image a bien été modifiée", setMessage);
    
                })
                .catch(err => {
                    displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
                });
        } else {
            setUser(null);
        }

    }

    function handleDeleteColor(productId, colorId) {
        if (hasAuthenticated()) {
            axios.delete(
                `${process.env.REACT_APP_HOST}/api/products/${productId}/colors/${colorId}`,
                {headers : {"Authorization" : `Bearer ${user.token}`},
                data : {userId : user.userId}},   
            )
            .then(res => {
                const product = products.find((element) => element._id === productId);
                const colorImageIndex = product.gallery.findIndex((element) => element._id === res.data.colorId)
                product.gallery.splice(colorImageIndex, 1);
                setProducts([...products]);
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    }
    
    return (
        <div>
            <div className="bg"></div>
            <main className="pt-5">
                <StandardButton
                text="Ajouter un produit"
                onClick={() => {setShowModal(true)}}
                />
                {showModal &&
                    <ProductModal
                    buttonOnClick={handleCreate}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    buttonText="Créer un produit"
                    /> 
                    }
                    {products.map((e) => (
                        <div key={e._id}>
                            <Product
                            buttonText="Modifier le produit"
                            buttonOnClick={handleUpdate}
                            handleColor={handleColor}
                            handleUpdateColor={handleUpdateColor}
                            handleDelete={handleDelete}
                            handleDeleteColor={handleDeleteColor}
                            productId={e._id}
                            name={e.name}
                            composition={e.composition}
                            retailPrice={e.retailPrice}
                            wholeSalePrice={e.wholeSalePrice}
                            sizes={e.sizes}
                            gallery={e.gallery}
                            productStar={e.productStar}
                            />
                        </div>
                    ))}
                    {showAlert &&
                        <AlertModal
                        className="text-success px-3"
                        showModal={showAlert}
                        setShowModal={setShowAlert}
                        alertText={Message}
                        />
                    }
                    {showErrorAlert &&
                        <AlertModal
                        className="text-danger px-3"
                        showModal={showErrorAlert}
                        setShowModal={setShowErrorAlert}
                        alertText={Message}
                        />
                    }  
            </main>
        </div>
    )
}

export default Products;
