import { getCookie, removeCookie } from "./cookiesTools";

export function hasAuthenticated() {
    const cookie = getCookie("admin");
    if (cookie && cookie.token) {
        if (tokenIsValid(cookie.token)) {
            return cookie;
        }
        removeCookie("admin");
    }
    return null;
}

export function tokenIsValid(token) {
    const exp = JSON.parse(atob(token.split(".")[1])).exp;
    return exp * 1000 > new Date().getTime();
}

export function checkToken(token) {
    const cookie = getCookie("admin");
    if (cookie && cookie.token && cookie.token === token) {
        return true;
    }
    return false;
}