import AlertModal from "../AlertModal";
import PasswordModal from "../PasswordModal";
import AdminAccountModal from "./AdminAccountModal";
import {useContext, useState} from "react";
import AuthContext from "../../context/AuthContext";
import {GoTrashcan} from "react-icons/go";
import {BsPencil, BsThreeDots} from "react-icons/bs";
import { Dropdown } from "react-bootstrap";
import DeleteAlert from "../DeleteAlert";


function AdminData(props) {

    const {user} = useContext(AuthContext);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    return (
        <div className="account-container justify-content-around align-items-center mb-2 p-2 rounded-bottom text-break">
              {showAlert && 
                    <DeleteAlert 
                        onClose={() => setShowAlert(false)}
                        onClick={() => {props.handleDeleteAdminAccount(props.accountId); setShowAlert(false)}}
                        text={`Etes-vous sur de vouloir supprimer ce compte ?`}
                    />
            }
            <div className="row m-0 p-2 text-break">
                <div className="col">
                    <div className="pb-2 col-auto fw-bolder text-wrap">
                        <p>{props.firstName} {props.lastName}</p>
                    </div>
                    <div className="col-auto pb-2 fw-bolder">
                        <p>{props.email}</p>
                    </div>
                </div>
                <div className="col-2 text-center justify-content-center">
                    <Dropdown
                        drop="up"
                        align="end"
                    >
                        <Dropdown.Toggle className="bg-transparent shadow-none border-light p-0 m-0">
                            <BsThreeDots size={24} color="black" aria-label="ouvrir le menu à developper"/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            className="dropdown-product-menu"
                        >
                            <Dropdown.Item 
                                className="dropdown-product-menu__item"
                                onClick={() => setShowAccountModal(true)}
                            >
                                Modifier
                            </Dropdown.Item>
                            <Dropdown.Item 
                                className="dropdown-product-menu__item"
                                onClick={() => setShowAlert(true)} 
                            >
                                Supprimer
                            </Dropdown.Item>
                            {showPasswordModal && 
                                <PasswordModal
                                handleUpdatePassword={props.handleUpdatePassword}
                                showModal={showPasswordModal}
                                setShowModal={setShowPasswordModal}
                                accountId={props.accountId}
                                />
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {showPasswordModal && 
                <PasswordModal
                handleUpdatePassword={props.handleUpdatePassword}
                showModal={showPasswordModal}
                setShowModal={setShowPasswordModal}
                accountId={props.accountId}
                />
            }
            {showAccountModal && 
                <AdminAccountModal 
                    handleUpdateAdminData={props.handleUpdateAdminData}
                    accountId={props.accountId}
                    showModal={showAccountModal}
                    setShowModal={setShowAccountModal}
                    firstName={props.firstName}
                    lastName={props.lastName}
                    email={props.email}
                    buttonText="Enregistrer les modifications"
                /> 
            }
        </div>
    )
};

export default AdminData;