import { useContext } from "react";
import {AiOutlineFacebook, AiOutlineInstagram, AiOutlineLinkedin} from "react-icons/ai";
import { Link } from "react-router-dom";
import SettingsContext from "../context/SettingsContext";
import { translate } from "../services/translation/translate";
import "./Footer.scss";
 
function Footer() {

    const {settings} = useContext(SettingsContext);

    return (
        <footer className="bg-black text-white py-3 website-footer">
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="p-2 text-center">
                    <h2 style={{fontSize: "1.2em"}}>{translate.footer.footerText1[settings.language]} &reg; {translate.footer.footerText2[settings.language]}</h2>
                    <p style={{fontSize: "0.9em"}}>4 Avenue du Valquiou 93200 Tremblay-en-France</p>
                </div>
                <div className="d-flex gap-3 m-auto pt-3 px-5">
                    <a aria-label="page facebook" to="https://fr-fr.facebook.com/BombersOriginal/" href="https://fr-fr.facebook.com/BombersOriginal/"><AiOutlineFacebook size={40} className="social-network-logo facebook"/></a>
                    <a aria-label="page instagram" to="https://www.instagram.com/bombers_original/?hl=fr" href="https://www.instagram.com/bombers_original/?hl=fr"><AiOutlineInstagram size={40} className="social-network-logo instagram"/></a>
                    <a aria-label="page linkedin" to="https://www.linkedin.com/company/bombers-original-partenariats/" href="https://www.linkedin.com/company/bombers-original-partenariats/"><AiOutlineLinkedin size={40} className="social-network-logo linkedin"/></a>
                </div>
            </div>

        </footer>
    )
}

export default Footer;

