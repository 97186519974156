import {Modal} from "react-bootstrap";


function AlertModal(props) {

    return (
        <Modal 
            className="mt-3 d-inline-flex justify-content-center"
            show={props.showModal} 
            aria-labelledby={props.name}
        >
            <Modal.Header className="border-0">
            <p className={props.className}>{props.alertText}</p>
            </Modal.Header>
        </Modal>
    )
}

export default AlertModal;