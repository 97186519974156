import "./scss/custom.scss";
import React from "react";
import SettingsContext from "./context/SettingsContext";
import { Routes, Route, Navigate } from "react-router-dom";
import { useState } from "react";
import Header from "./components/header/Header";
import { getSettings } from "./services/settingsTools";
import Home from "./components/Home";
import Contact from "./components/Contact"
import Footer from "./components/Footer";
import Products from "./components/Products";
import Product from "./components/Product";
import PartnerGallery from "./components/PartnerGallery";
import ErrorPage from "./components/ErrorPage";
import NotFoundPage from "./components/NotFoundPage";

function Customer() {

    const [settings, setSettings] = useState(getSettings());

    return (
        <SettingsContext.Provider value={{settings, setSettings}}>
            <Header/>
            <Routes>
                <Route exact path="/home" element={<Home/>} />
                <Route exact path="/contact" element={<Contact/>} />
                <Route path="/products" element={<Products/>}/>
                <Route path="/products/:id" element={<Product/>}/>
                <Route path="/gallery" element={<PartnerGallery/>}/>
                <Route path="/error-500" element={<ErrorPage/>}/>
                <Route path="/*" element={<NotFoundPage/>}/>
                <Route exact path="/" element={<Navigate to="/home" replace={true}/>} />
            </Routes>
            <Footer/>
        </SettingsContext.Provider>
    )
}

export default Customer;