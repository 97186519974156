import AdminHeader from "../../header/AdminHeader";
import notFound from "../../../assets/not-found.png";
import StandardButton from "../../button/StandardButton";
import { useNavigate } from "react-router-dom";
import "./ErrorPage.scss";

function NotFoundPage() {

    let navigate = useNavigate();

    return (
        <div className="bg">
            <main className=" vh-100 d-flex container align-items-center justify-content-center">
                <div className="bg-light p-3 rounded bg-opacity-75 text-center p-4">
                    <img className="error-img" src={notFound} alt="not found 404 logo" />
                    <h1 className="pt-3 text-uppercase fw-bold mh-0">Not found</h1>
                    <p className="fs-4">La page recherchée est introuvable</p>
                    <StandardButton 
                    variant="black"
                    text="retour"
                    onClick={()=> {navigate(-1)}}
                    />
                </div>
            </main>
        </div>
    )
}

export default NotFoundPage;