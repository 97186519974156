import { useState, useEffect } from "react";
import {Form, Button, ProgressBar} from "react-bootstrap";
import "./SignUpForm.scss";
import {CgDanger} from "react-icons/cg";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import { handleProgress } from "../../services/progressBarTools";
import {useSearchParams} from "react-router-dom";
import AlertModal from "../AlertModal";
import {displayError} from "../../services/errorsTools";
import {AiOutlineEyeInvisible, AiOutlineEye} from "react-icons/ai";
import {displayErrorMessage} from "../../services/errorsTools";



function SignUpForm() {
    const [searchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [invitationId, setInvitationId] = useState(null);
    const [showProgress, setShowProgress] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [Message, setMessage] = useState("");
    const token = searchParams.get("token");
    let navigate = useNavigate();
    const emailRegex = /^([a-zA-Z0-9\.-_]+)@([a-zA-Z0-9-_]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
    const passwordRegexes = [
        // Uppercase regex
        /(.*[A-Z])/,
        // Lowercase regex
        /(.*[a-z])/,
        // Number regex
        /(.*[0-9]{3,})/,
        // Symbol regex
        /(.*\W)/,
        // Min length regex
        /^.{8,}$/
    ]

    const [credentials, setCredentials] = useState({
        firstName : "",
        lastName : "",
        email : "",
        password : ""
    })

    const [progressBar, setProgressBar] = useState({
        color : "danger",
        progress : 0
    });

    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_HOST}/api/admin/invitations/${token}`)
        .then(res => {
            if (res.status === 200) {
                setInvitationId(res.data._id);
            }
        })
        .catch(err => {
            displayErrorMessage(setShowErrorAlert, err, setMessage, navigate)
        });
        
     }, [])

    function handleChange({currentTarget}) {
        const {name, value} = currentTarget;
        setCredentials({...credentials, [name] : value});
    };


    function handleForm(e) {
        e.preventDefault();
        axios.post(
            `${process.env.REACT_APP_HOST}/api/admin/signup`,
            {
                invitationId : invitationId,
                ...credentials
            }
        )
        .then(async (res) => {
            if (res.status === 201) {
                setShowModal(true);
                await new Promise(setTimeout( () => navigate("/admin/login", {replace: true}), 2000));
            }
        })
        .catch(err => {
            const error = err.response.data.error;
            const errorMessage = displayError(err, navigate);
            setPasswordError(error.includes("PASSWORD") ? errorMessage : null);
            setEmailError(error.includes("EMAIL") ? errorMessage : null);
        });
    }

    return (
        
        <div className="bg-auth">
            <main className="vh-100 d-flex container py-5 col-sm-12 col-md-8 col-lg-5 align-items-center justify-content-center">
                {!invitationId ?
                    <div className="bg-light p-3 rounded bg-opacity-75">
                        <h1 className="fs-4 fw-bolder text py-3 text-center text-tertiary">Cette page n'est plus accessible.</h1>
                        <p className=" fw-bolder text text-center text-tertiary"> Pour renouveller l'invitation, veuillez contacter l'administrateur des comptes.</p>
                    </div>
                : 
                    <div className="bg-light p-3 rounded bg-opacity-75">
                        <h1 className="fs-2 fw-bolder text py-3 text-center text-tertiary">Inscription Bombers Partenariats Administrateur</h1>
                        <Form onSubmit={handleForm}>
                            <Form.Group controlId="firstName">
                                <Form.Label className="fw-bolder">Prénom</Form.Label>
                                <Form.Control
                                    className="signup-control rounded-0 bg-transparent border-bottom border-black p-0 mb-2"
                                    onChange={(e) =>handleChange(e)}
                                    value={credentials.firstName}
                                    name="firstName" 
                                    type="text" 
                                    autoComplete="on"
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="lastName">
                                <Form.Label className="fw-bolder">Nom</Form.Label>
                                <Form.Control
                                    className="signup-control rounded-0 bg-transparent border-bottom border-black p-0 mb-2"
                                    onChange={(e) =>handleChange(e)}
                                    value={credentials.lastName}
                                    name="lastName" 
                                    type="text" 
                                    autoComplete="on"
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label className="fw-bolder">E-mail</Form.Label>
                                <Form.Control
                                    className={emailError != null ? "signup-control bg-transparent rounded-0 border-bottom border-danger p-0" : "login-control rounded-0 bg-transparent border-bottom border-black p-0 mb-2"}
                                    onChange={(e) =>handleChange(e)}
                                    value={credentials.email}
                                    name="email" 
                                    type="email" 
                                    autoComplete="on"
                                    required
                                />
                                {emailError != null && 
                                    <div className="d-flex mt-2 text-white">
                                        <span>
                                            <CgDanger color="red"/>
                                        </span>
                                            <p className="px-2 text-danger fw-bolder">{emailError}</p>
                                    </div>
                                }
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label className="fw-bolder">Mot de passe</Form.Label>
                                <div className="position-relative">
                                    <Form.Control 
                                        className={passwordError != null ? "signup-control bg-transparent rounded-0 border-bottom border-danger pe-5 ps-0 py-0" : "signup-control rounded-0 bg-transparent border-bottom border-black pe-5 ps-0 py-0 mb-2"}
                                        onChange={(e) => {
                                            handleChange(e);
                                            const progress = handleProgress(passwordRegexes, e.target.value);
                                            setProgressBar(progress);
                                            setShowProgress(true);
                                        }}
                                        value={credentials.password}
                                        autoComplete="current-password"
                                        name="password" 
                                        type={showPassword ? "text" : "password"}
                                        required
                                    />
                                    {showPassword ? <AiOutlineEye size={22} className="password-icon" onClick={() => setShowPassword(false)}/>: <AiOutlineEyeInvisible size={22} className="password-icon" onClick={() => setShowPassword(true)}/> }
                                </div>
                                {showProgress && 
                                    <div>
                                        <p style={{fontSize: ".80rem"}}>Le mot de passe doit contenir au moins 8 caractères dont 1 majuscule, 1 minuscule, 1 caractère spécial et 3 chiffres</p>
                                        <ProgressBar now={progressBar.progress} variant={progressBar.color} />
                                    </div>
                                }
                                {passwordError != null && 
                                <div className="d-flex mt-2 text-white">
                                    <span>
                                        <CgDanger color="red"/>
                                    </span>
                                    <p className="px-2 text-danger fw-bolder">{passwordError}</p>
                                </div>
                                }
                                {showModal &&
                                    <AlertModal 
                                    setShowModal={setShowModal}
                                    showModal={showModal}
                                    alertText="Votre compte a été crée avec succès"
                                    />
                                }
                                {showErrorAlert &&
                                    <AlertModal
                                    className="text-danger px-3 fw-bold"
                                    showModal={showErrorAlert}
                                    setShowModal={setShowErrorAlert}
                                    alertText={Message}
                                    />
                                }
                            </Form.Group>
                            <div className="text-center p-3">
                                <Button
                                disabled={progressBar.progress < 100 || !emailRegex.test(credentials.email || credentials.firstName.length === 0 || credentials.lastName === 0)}
                                type="submit"
                                variant="primary"
                                className="fs-5 text-white fw-bold border-primary"
                                >
                                    Connexion
                                </Button>
                            </div>
                        </Form>
                    </div>
                }   

            </main> 
        </div>
    )
}

export default SignUpForm;