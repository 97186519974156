import StandardButton from "../button/StandardButton";
import {FaTrashAlt} from "react-icons/fa";
import { useState } from "react";
import DeleteAlert from "../DeleteAlert";

function Invitation(props) {
    const [showAlert, setShowAlert] = useState(false);

    return (
        <div>
            {showAlert && 
                <div className="p-3">
                    <DeleteAlert 
                        onClose={() => setShowAlert(false)}
                        onClick={() => {props.handleDeleteInvitation(props.invitationId); setShowAlert(false)}}
                        text={`Etes-vous sur de vouloir supprimer l'invitation de ${props.email} ?`}
                    />
                </div>
            }
            <div className="d-flex justify-content-between align-items-center fw-bolder p-2">
                <div>
                    <p className="m-0">{props.email}</p>
                </div>
                <StandardButton 
                variant="black"
                text={<FaTrashAlt/>}
                onClick={() => setShowAlert(true)}
                />
            </div>
        </div>
    )
}

export default Invitation;