import {BsThreeDots} from "react-icons/bs";
import { Dropdown, Form } from "react-bootstrap";
import "./Product.scss";
import ProductCard from "./ProductCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProductModal from "./ProductModal";
import { useState } from "react";
import DeleteAlert from "../DeleteAlert";
import ProductColorModal from "./ProductColorModal";


function Product(props) {
    const [showProductModal, setShowProductModal] = useState(false);
    const [showColorModal, setShowColorModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const responsiveRules = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 1200, min: 998 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 998, min: 576 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 2
        }
    };

    function reorderSizes(unorderedSizes) {
        const orderedSizes = ["XS", "S", "M", "L", "XL", "XXL", "XXXL", "XXXXL", "Unique"];
        let sizesList = [];
        for (let size of orderedSizes) {
            if (unorderedSizes.includes(size)) {
                sizesList.push(size);
            }
        }
        return sizesList;
    }

    return (
        <article className="container product-container rounded my-3 px-0 position-relative">
            <div className="bg-black rounded-top text-white p-2">
                <h2 className="fs-4 text-uppercase text-center fw-bold m-0 px-5 py-2">{props.name}</h2>
                <Dropdown
                className="product-container__toggle-container"
                align="end"
                >
                    <Dropdown.Toggle className="product-container__toggle-container__toggle bg-transparent shadow-none border-light p-0 m-0"><BsThreeDots size={24} color="white" aria-label="ouvrir le menu à developper"/></Dropdown.Toggle>
                    <Dropdown.Menu
                    className="dropdown-product-menu"
                    >
                        <Dropdown.Item 
                        className="dropdown-product-menu__item"
                        onClick={() => setShowProductModal(true)}
                        >
                            Modifier
                        </Dropdown.Item>
                        <Dropdown.Item 
                        className="dropdown-product-menu__item"
                        onClick={() => setShowAlert(true)}
                        >
                            Supprimer
                        </Dropdown.Item>
                        <Dropdown.Item 
                        className="dropdown-product-menu__item"
                        onClick={() => setShowColorModal(true)}
                        >
                            Ajouter une image
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="p-3 text-center fw-bolder position-relative">
                {showAlert &&
                    <DeleteAlert 
                    onClose={() => setShowAlert(false)}
                    onClick={() => props.handleDelete(props.productId)}
                    text={`Etes-vous sur de vouloir supprimer le produit ${props.name}`}
                    />
                }
                <div className="pb-2">
                    <h3 className="fw-bolder fs-6 ">Composition</h3>
                    <p>{`${props.composition.fr} / ${props.composition.en}`}</p>
                </div>
                <div  className="pb-2">
                    <h3 className="fw-bolder fs-6 ">Prix au détail</h3>
                    <p>{`${props.retailPrice} €`}</p>
                </div>
                <div  className="pb-2">
                    <h3 className="fw-bolder fs-6 ">Prix de gros</h3>
                    <p>{`${props.wholeSalePrice} €`}</p>
                </div>
                <h3 className="fw-bolder fs-6 pb-3">Tailles disponibles</h3>
                <div className="d-flex flex-wrap justify-content-around w-75 mx-auto">
                    {reorderSizes(props.sizes).map((e) => 
                        <div key={e} className="badge bg-black text-white vertical-align mb-2">
                            <p className="m-0 fs-6">{e}</p>
                        </div>
                    )} 
                </div>
                {props.productStar === true &&
                    <div className="p-2 d-flex justify-content-center">
                        <Form.Check readOnly checked label="produit star"/>
                    </div>
                }
            </div>
            <Carousel 
            responsive={responsiveRules} 
            className={props.gallery.length === 0 ?"m-2 align-items-center justify-content-center" : "m-2 align-items-center"}
            swipeable={true}
            >
                {props.gallery.map((e) => 
                    <ProductCard 
                    productName={props.name}
                    handleUpdateColor={props.handleUpdateColor}
                    handleDeleteColor={props.handleDeleteColor} 
                    productId={props.productId} 
                    colorId={e._id} 
                    key={e._id}
                    src={e.imageUrl} 
                    color={e.color}
                    />
                )}
            </Carousel>
            {showColorModal && 
                <ProductColorModal 
                showModal={showColorModal} 
                setShowModal={setShowColorModal}
                productId={props.productId}
                buttonOnClick={props.handleColor}
                buttonText="Enregistrer l'image"
                />
            }
            {showProductModal &&
                <ProductModal
                buttonText={props.buttonText}
                buttonOnClick={props.buttonOnClick}
                productId={props.productId}
                sizes={props.sizes}
                setShowModal={setShowProductModal}
                showModal={showProductModal}
                name={props.name} 
                composition={props.composition} 
                retailPrice={props.retailPrice}
                wholeSalePrice={props.wholeSalePrice}
                productStar={props.productStar}
                />
            }
        </article>
    )
}

export default Product;