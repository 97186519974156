export const productTranslation = {
    headTitle : {
        fr : "Bombers Original Partenariats - ",
        en : "Bombers Original Partnerships - "
    },
    headDescription : {
        fr : "Bombers Original Partenariats vous présente l'un de nos nombreux modèles de Bombers Original personnalisables aux couleurs de votre entreprise et/ou votre club.", 
        en : "One of our numerous customizable Original Bombers Partners in the colors of your Company or Club."
    },
    sizes : {
        fr : "Tailles disponibles",
        en  : "Available sizes"
    },
    composition : {
        fr : "Composition",
        en : "Material"
    },
    retailPrice : {
        fr : "Retail price",
        en : "Retail price"
    },
    wholeSalePrice : {
        fr : "Wholesale price",
        en : "Wholesale price"
    }, 
    buttonText : {
        fr : "Retour aux produits",
        en : "Go back to products"
    }
}
