import {Form, Modal} from "react-bootstrap";
import {useContext, useState} from "react";
import StandardButton from "../button/StandardButton";
import AuthContext from "../../context/AuthContext";
import { checkLanguagesEntries } from "../../services/languagesTools";


function ProductModal(props) {
    
    const {user} = useContext(AuthContext);
    const numbersRegex = /(.*[0-9]{1,})/;
    const [sizes, setSizes] = useState(props.sizes ? props.sizes : []);
    const [productStar, setProductStar] = useState(props.productStar); // === true ? true : false);
    const [product, setProduct] = useState({
        userId: user.userId,
        productId : props.productId,
        name : props.name ? props.name : "",
        composition : props.composition ? props.composition : {},
        retailPrice : props.retailPrice ? props.retailPrice : "",
        wholeSalePrice : props.wholeSalePrice ? props.wholeSalePrice : "",
        sizes : sizes,
        productStar : props.productStar ? props.productStar : false
    })

    function handleChange({currentTarget}) {
        const {name, value} = currentTarget;
        if (["fr", "en"].includes(name)) {
            product.composition[name] = value;
        }
        else {
            product[name] = value;
        }
        setProduct({...product});
    }

    function handleProductStar() {
        product.productStar = !productStar;
        setProductStar(!productStar);
    }

    function handleSizes(e) {
        if (!sizes.includes(e.target.value)) {
            sizes.push(e.target.value);
        }
        else {
            let index = sizes.findIndex((element) => element === e.target.value);
            sizes.splice(index, 1);
        }
        setSizes([...sizes]);
        product.sizes = sizes;
    }

    function checkProductEntries(product) {
        if (product.name.length === 0 || product.sizes.length === 0 || !checkLanguagesEntries(product.composition) || !numbersRegex.test(product.retailPrice) || !numbersRegex.test(product.wholeSalePrice)) {
            return false;
        }
        return true;
    }


    return (
        <Modal 
            show={props.showModal} 
            aria-labelledby={props.name}
            onHide={() => props.setShowModal(false)}
            centered
        >
            <Modal.Header className="border-0" closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formName" >
                    <Form.Label className="text-primary fw-bolder">Nom du produit</Form.Label>
                    <Form.Control 
                        onChange={(e) => handleChange(e)} 
                        className="post-input rounded-3 mb-2" 
                        name="name"
                        value={product.name}
                    />           
                </Form.Group>
                <Form.Group
                controlId="formCompositionFr" >
                    <Form.Label className="text-primary fw-bolder">Composition en francais</Form.Label>
                    <Form.Control 
                        onChange={(e) => handleChange(e)} 
                        className="post-input rounded-3 mb-2" 
                        name="fr"
                        value={product.composition.fr}
                    />           
                </Form.Group>
                <Form.Group controlId="formCompositionEn" >
                    <Form.Label className="text-primary fw-bolder">Composition en anglais</Form.Label>
                    <Form.Control 
                        onChange={(e) => handleChange(e)} 
                        className="post-input rounded-3 mb-2" 
                        name="en"
                        value={product.composition.en}
                    />           
                </Form.Group>
                <Form.Group controlId="formRetailPrice" >
                    <Form.Label className="text-primary fw-bolder">Prix au detail</Form.Label>
                    <Form.Control 
                        onChange={(e) => handleChange(e)} 
                        className="post-input rounded-3 mb-2" 
                        name="retailPrice"
                        value={product.retailPrice}
                    />           
                </Form.Group>
                <Form.Group controlId="formWholsalePrice" >
                    <Form.Label className="text-primary fw-bolder">Prix de gros</Form.Label>
                    <Form.Control 
                        onChange={(e) => handleChange(e)} 
                        className="post-input rounded-3 mb-2" 
                        name="wholeSalePrice"
                        value={product.wholeSalePrice}
                    />           
                </Form.Group>
                <Form.Group controlId="formSizes" >
                    <Form.Label  className="text-primary fw-bolder">Tailles disponibles</Form.Label>
                        <div key={`inline-checkbox`} className="mb-3 text-center">
                            {["XS", "S", "M", "L", "XL", "XXL", "XXXL", "XXXXL", "Unique"].map(e =>
                                <Form.Check 
                                    key={e}
                                    inline
                                    label={e}
                                    name="sizes"
                                    onChange={(event) => handleSizes(event)}
                                    type="checkbox"
                                    id={`inline-checkbox-${e}`}
                                    value={e}
                                    checked={sizes.includes(e)}
                                />)
                            }
                        </div>        
                </Form.Group>
                <Form.Group controlId="productStar" >
                    <div className="mb-3">
                        <Form.Check inline
                            label='produit Star'
                            onChange={() => handleProductStar()}
                            name="sizes"
                            type="checkbox"
                            id="productStar"
                            value='productStar'
                            checked={product.productStar} 
                        />
                    </div>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer> 
                <StandardButton
                    disabled={!checkProductEntries(product)}
                    variant="black" 
                    text={props.buttonText} 
                    onClick={() => {props.buttonOnClick(product); props.setShowModal(!props.showModal)}}
                />
            </Modal.Footer>
        </Modal>
    )
}

export default ProductModal;
