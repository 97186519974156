import {getCookie, setCookie} from "./cookiesTools";

function getNavigatorLanguage() {
    const cookieLanguage = getCookie("settings");
    if (cookieLanguage && cookieLanguage.language) {
        document.documentElement.lang = cookieLanguage.language;
        return cookieLanguage.language;
    }
    const Navlanguage = navigator.languages && navigator.languages.length > 0
        ? navigator.languages[0]
        : navigator.language;
        const language = Navlanguage.startsWith("fr") ? "fr" : "en";
        document.documentElement.lang = language;
    return language.startsWith("fr") ? "fr" : "en";
}

export function getSettings() {
    return {
        language : getNavigatorLanguage()
    };
}