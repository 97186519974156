import { useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SettingsContext from "../context/SettingsContext";
import { translate } from "../services/translation/translate";

function NotFoundPage() {
    let navigate = useNavigate();
    const {settings} = useContext(SettingsContext);

    return (
        <div>
            <div className="bg-contact"></div>
            <main className="vh-100 d-flex container align-items-center justify-content-center">
                <div className="bg-black-opacity p-5 text-white bg-opacity-75 text-center p-4">
                    <h1 className="pt-3 text-uppercase fw-bold mh-0">{translate.notFoundPage.errorText1[settings.language]}</h1>
                    <p className="fs-4">{translate.notFoundPage.errorText2[settings.language]}</p>
                    <Button 
                    className="mt-4"
                    variant="outline-primary text-white"
                    onClick={()=> {navigate("/home", {replace: true})}}
                    >
                        {translate.notFoundPage.buttonText[settings.language]}
                    </Button>
                </div>
            </main>
    </div>
    )
}

export default NotFoundPage;