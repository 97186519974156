import { useState } from "react";
import { Modal, Form, ProgressBar } from "react-bootstrap";
import { handleProgress } from "../services/progressBarTools";
import StandardButton from "./button/StandardButton";
import {AiOutlineEyeInvisible, AiOutlineEye} from "react-icons/ai";
import "./PasswordModal.scss";


function PasswordModal(props) {

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [passwordData, setPasswordData] = useState({
        accountId: props.accountId,
        oldPassword: "",
        newPassword: ""
    });
    const [progressBar, setProgressBar] = useState({
        color : "danger",
        progress : 0
    });
    const [showProgress, setShowProgress] = useState(false);
    const passwordRegexes = [
        // Uppercase regex
        /(.*[A-Z])/,
        // Lowercase regex
        /(.*[a-z])/,
        // Number regex
        /(.*[0-9]{3,})/,
        // Symbol regex
        /(.*\W)/,
        // Min length regex
        /^.{8,}$/
    ];

    function handleChange({currentTarget}) {
        const {name, value} = currentTarget;
        setPasswordData({...passwordData, [name] : value});
    };

    function checkNewPassword(regexes, newPassword) {
        let regexError = 0;
        for (let regex of regexes) {
            if(!regex.test(newPassword)) {
                regexError ++;  
            } 
        }
        return regexError === 0 ? true : false;
    };

    return (
        <Modal  
        show={props.showModal} 
        aria-labelledby={props.headerText}
        onHide={() => props.setShowModal(false)}
        centered
        >
            <Modal.Header className="border-0" closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form>
                <Form.Group controlId="formOldPassword" >
                    <Form.Label>Mot de passe actuel</Form.Label>
                <div className="position-relative">
                    <Form.Control 
                        onChange={handleChange} 
                        type={showOldPassword ? "text" : "password"}
                        className="password-control rounded-3 mb-2 pe-5" 
                        name="oldPassword"
                        autoComplete="current-password"
                        value={passwordData.oldPassword}
                    />           
                    {showOldPassword ? <AiOutlineEye size={22} className="password-icon" onClick={() => setShowOldPassword(false)}/>: <AiOutlineEyeInvisible size={22} className="password-icon" onClick={() => setShowOldPassword(true)}/> }
                </div>
                </Form.Group>
                <Form.Group controlId="formNewPassword" >
                    <Form.Label>Nouveau mot de passe</Form.Label>
                    <div className="position-relative">
                        <Form.Control 
                            onChange={(e) => {
                                handleChange(e);
                                const progress = handleProgress(passwordRegexes, e.target.value);
                                setProgressBar(progress);
                                setShowProgress(true);
                            }}
                            type={showNewPassword ? "text" : "password"}
                            className="password-control rounded-3 mb-2 pe-5" 
                            name="newPassword"
                            autoComplete="new-password"
                            value={passwordData.newPassword}
                        />  
                        {showNewPassword ? <AiOutlineEye size={22} className="password-icon" onClick={() => setShowNewPassword(false)}/>: <AiOutlineEyeInvisible size={22} className="password-icon" onClick={() => setShowNewPassword(true)}/> }
                    </div>
                </Form.Group>
                {showProgress && 
                    <div>
                         <p style={{fontSize: ".80rem"}}>Le mot de passe doit contenir au moins 8 caractères dont 1 majuscule, 1 minuscule, 1 caractère spécial et 3 chiffres</p>
                        <ProgressBar now={progressBar.progress} variant={progressBar.color} />
                    </div>
                }
                </Form>
            </Modal.Body>
            <Modal.Footer className="position-relative">
                <StandardButton
                    onClick={() => {props.handleUpdatePassword(passwordData); props.setShowModal(false)}}
                    disabled={!checkNewPassword(passwordRegexes, passwordData.newPassword)}
                    variant="black"
                    text="Valider le changement de mot de passe" 
                />
            </Modal.Footer>
        </Modal>
    );
}

export default PasswordModal;