import "./Home.scss";
import Carousel from "react-multi-carousel";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Button, Card } from "react-bootstrap";
import logo from "../assets/logo_bombers_white.png";
import { translate } from "../services/translation/translate";
import SettingsContext from "../context/SettingsContext";
import { useNavigate } from "react-router-dom";
import Gallery from "./Gallery";
import {Helmet} from "react-helmet";

function Home() {

    let navigate = useNavigate();
    const [productsStar, setProductsStar] = useState([]);
    const [partners, setPartners] = useState([]);
    const {settings} = useContext(SettingsContext);

    const responsiveRules = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: productsStar.length < 5 ? productsStar.length : 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 998 },
            items: productsStar.length < 4 ? productsStar.length : 4
        },
        tablet: {
            breakpoint: { max: 998, min: 576 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 1,
        }
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_HOST}/api/products`)
       .then((res) => {
            const response = res.data;
            let ps = [];
            response.map((e) => {
                if (e.productStar === true) {
                    ps.push(e);
                }
            })
            setProductsStar(ps);
       })
       .catch(err => {
            navigate("/error-500");
       });
       axios.get(`${process.env.REACT_APP_HOST}/api/partners`)
       .then((res) => {
           const response = res.data;
           setPartners(response);
       })
       .catch(err => {
            navigate("/error-500");
       });
    },[])

    return (
        <div>
            <Helmet>
                <title>{translate.home.headTitle[settings.language]}</title>
                <meta name="description" content={translate.home.headDescription[settings.language]}/>
            </Helmet>
            <div className="bg-website"></div>
            <main className="text-center main-padding-top">
                <section className="home-section d-flex flex-column justify-content-center">
                    <h1 className="main-title text-nowrap fw-bolder text-uppercase my-5 p-2">{translate.home.title1[settings.language]} &reg; <br/> {translate.home.title2[settings.language]} </h1>
                    <div className="bg-black-opacity text-center text-white py-5 px-4">
                        <p className="fs-4">{translate.home.legendaryBrand[settings.language]}</p>
                        <p className="fs-4 pt-3">{translate.home.phrase1[settings.language]} &reg; {translate.home.phrase2[settings.language]}</p>
                        <p className="fs-4 pt-3">{translate.home.phrase3[settings.language]}</p>
                        <div className="w-75 mb-3 m-auto border-bottom border-2 border-silver">
                            <img className="home-bombers-logo" src={logo} alt={`${translate.home.title1[settings.language]} logo`}/>
                        </div>
                        <p className="fs-4 main-text">{translate.home.contact[settings.language]}</p>
                        <Button 
                            variant="outline-primary" 
                            className="m-3 fs-5 fw-bolder text-white"
                            onClick={() => navigate("/contact")}
                        >
                            {translate.home.contactButton[settings.language]}
                        </Button>
                    </div>
                </section>
                <section className="home-section d-flex flex-column justify-content-center">
                    <h2 className="main-text text-uppercase fw-bolder mb-5">{translate.home.products[settings.language]}</h2>
                    <div className="bg-black-opacity fs-4 text-white text-center py-5 p-3">
                            <p>{translate.home.productText1[settings.language]}</p>
                            <p>{translate.home.productText2[settings.language]}</p>
                        <div className="w-75 m-auto px-5 border-bottom border-2 border-silver pb-5"></div>
                        <div className="m-auto w-75">
                        <Carousel 
                            responsive={responsiveRules} 
                            className="m-2"
                            swipeable={true}
                        >
                            {productsStar.map((e) => 
                                <Card key={e._id} className="h-100 position-relative p-2 m-2 bg-transparent">
                                    <Card.Body className="h-100 bg-opacity-25 rounded p-0">
                                        <Card.Img className="main-product-image p-2 m-auto img-fluid" src={e.gallery[0].imageUrl} alt={`Bombers Original ${e.name}`} />
                                    </Card.Body>
                                    <Card.Title className="text-center pb-3 text-uppercase fw-bold">{e.name}</Card.Title>
                                </Card>
                            )}
                        </Carousel>
                        </div>
                        <Button 
                            variant="outline-primary" 
                            className="text-white fs-5 fw-bolder"
                            onClick={() => navigate("/products")}
                        >
                            {translate.home.productButtonText[settings.language]}
                        </Button> 
                    </div> 
                </section>
                <section className="d-flex flex-column justify-content-center">
                    <h2 className=" main-text fw-bolder text-uppercase mb-5">{translate.home.partners[settings.language]}</h2>
                    <div className="h-100 bg-black-opacity text-center text-white py-5 px-3">
                        <p className="fs-4">{translate.home.partnersText[settings.language]}</p>
                        <div className="w-75 m-auto px-5 border-bottom border-2 border-silver pb-5"></div>
                        <div className="gap-3 pt-5 d-flex flex-row flex-wrap justify-content-center align-items-center">
                            {partners.map((e) => (
                                <img key={e._id} className="p-3 home-logo"src={e.imageUrl} alt={`${e.name} logo`}/>
                            ))}
                        </div>
                        <Button 
                            variant="outline-primary" 
                            className="text-white fs-5 fw-bolder my-5"
                            onClick={() => navigate("/gallery")}
                        >
                            {translate.home.partnersButtonText[settings.language]}
                        </Button>
                    </div>
                    <Gallery/>
                </section>
            </main>
        </div>
    )
}

export default Home;