import axios from "axios";
import { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SettingsContext from "../context/SettingsContext";
import { translate } from "../services/translation/translate";
import {Helmet} from "react-helmet";


function Contact() {

    let navigate = useNavigate();
    const {settings} = useContext(SettingsContext);
    const [data, setData] = useState({
        firstName : "",
        lastName : "",
        email : "",
        company: "",
        company : "",
        message : "",
        language: settings.language
    })
    const [showValidation, setShowValidation] = useState(false);

    const emailRegex = /^([a-zA-Z0-9\.-_]+)@([a-zA-Z0-9-_]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;


    function handleChange({currentTarget}) {
        const {name, value} = currentTarget;
        setData({...data, [name] : value});
    };

    function handleForm(e) {
        e.preventDefault();
        axios.post(
            `${process.env.REACT_APP_HOST}/api/email`,
            data
        )
        .then(async (res) => {
            if (res.status === 200) {
                setShowValidation(true);
            }
        })
        .catch(err => navigate("/error-500"));
    }

    return (
        <div>
            <Helmet>
                <title>{translate.contact.headTitle[settings.language]}</title>
                <meta name="description" content={translate.contact.headDescription[settings.language]}/>
            </Helmet>
            <div className="bg-contact"></div>
            <div className="main-padding-top">
                <main className="py-5 col col-md-8 col-lg-6 m-auto">
                    <div className="bg-black-opacity text-white p-5 mb-5">
                    {showValidation ?
                        <div className="text-center">
                            <h1 className="fs-2 mb-3">{translate.contact.sendMessage1[settings.language]}</h1>
                            <p className="fs-4">{translate.contact.sendMessage2[settings.language]}</p>
                            <Button
                                type="submit"
                                variant="outline-primary"
                                className="mt-5 fs-5 fw-bold text-white"
                                onClick={(e) => navigate("/home")}
                            >
                                {translate.contact.requestText[settings.language]}
                            </Button>
                        </div>
                    :
                        <div>
                            <h1 className="fs-2 px-3 text-center">{translate.contact.mainText[settings.language]}</h1>
                            <Form className="pt-3">
                                <Form.Group className="pt-3">
                                    <Form.Label htmlFor="firstName" className="fw-bolder">{translate.contact.firstName[settings.language]}</Form.Label>
                                    <Form.Control
                                        autoFocus="false"
                                        id="firstName" 
                                        name="firstName" 
                                        type="text" 
                                        autoComplete="off"
                                        onChange={(e) => handleChange(e)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="pt-3">
                                    <Form.Label htmlFor="lastName" className="fw-bolder">{translate.contact.lastName[settings.language]}</Form.Label>
                                        <Form.Control 
                                            autoFocus="false"
                                            autoComplete="off"
                                            id="lastName" 
                                            name="lastName" 
                                            onChange={(e) => handleChange(e)}
                                            required
                                        />
                                </Form.Group>
                                <Form.Group className="pt-3">
                                    <Form.Label htmlFor="email" className="fw-bolder">{translate.contact.email[settings.language]}</Form.Label>
                                        <Form.Control 
                                            autoFocus="false"
                                            autoComplete="off"
                                            id="email" 
                                            name="email" 
                                            onChange={(e) => handleChange(e)}
                                            required
                                        />
                                </Form.Group>
                                <Form.Group className="pt-3">
                                    <Form.Label htmlFor="company"  className="fw-bolder">{translate.contact.company[settings.language]}</Form.Label>
                                        <Form.Control 
                                            autoFocus="false"
                                            autoComplete="current-password"
                                            id="company" 
                                            name="company"
                                            onChange={(e) => handleChange(e)} 
                                            required
                                        />
                                </Form.Group>
                                <Form.Group className="pt-3">
                                    <Form.Label htmlFor="message" className="fw-bolder">{translate.contact.message[settings.language]}</Form.Label>
                                        <Form.Control 
                                            autoFocus="false"
                                            as="textarea" 
                                            rows={3}
                                            autoComplete="off"
                                            id="message" 
                                            name="message"
                                            onChange={(e) => handleChange(e)}  
                                            required
                                        />
                                </Form.Group>
                                <div className="text-center pt-5">
                                    <Button
                                        disabled={data.firstName.length === 0 || data.lastName.length === 0 || data.company.length === 0 || data.message.length === 0 || !emailRegex.test(data.email)}
                                        type="button"
                                        variant="outline-primary"
                                        className="fs-5 fw-bold text-white"
                                        onClick={(e) => handleForm(e)}
                                    >
                                        {translate.contact.buttonText[settings.language]}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    }
                    </div>
                </main> 
            </div>
        </div>
    )
}

export default Contact;
