import {Card} from "react-bootstrap";
import StandardButton from "../button/StandardButton";
import "./ProductCard.scss";
import ProductColorModal from "./ProductColorModal";
import {useState} from "react";

function ProductCard(props) {
    const [showColorModal, setShowColorModal] = useState(false);


    return (
        <Card className="productcard position-relative p-2 m-2 bg-light bg-opacity-100">
            <Card.Title className="text-center pt-3 text-uppercase">{props.color.fr}</Card.Title>
            <Card.Img className="productcard__img p-2 m-auto img-fluid opacity-100" src={props.src} />
            <Card.Body className="p-0">
                <StandardButton text="modifier" onClick={() => setShowColorModal(!showColorModal)}/>
            </Card.Body>
            {showColorModal &&
                <ProductColorModal 
                productName={props.productName}
                buttonText="Modifier" 
                handleDeleteColor={props.handleDeleteColor} 
                buttonOnClick={props.handleUpdateColor} 
                colorId={props.colorId} 
                productId={props.productId} 
                showModal={showColorModal} 
                setShowModal={setShowColorModal} 
                color={props.color} 
                image={props.src}
                /> 
            }
        </Card>
    )
}
    

export default ProductCard;