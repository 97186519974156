import {Card} from "react-bootstrap";
import StandardButton from "../button/StandardButton";
import "./PartnerCard.scss";
import {useState} from "react";
import PartnerImageModal from "./PartnerImageModal";

function PartnerCard(props) {
    const [showPartnerImageModal, setShowPartnerImageModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);


    return (
        <Card className="position-relative p-2 m-2 bg-light bg-opacity-100">
            <img 
                className="partnercard-img p-2 m-auto img-fluid opacity-100" 
                src={props.image} 
                alt={`shooting de ${props.name}`}
             />
                <StandardButton 
                    text="Supprimer"
                    onClick={() => {setShowPartnerImageModal(true); setShowAlert(true)}}
                />
                {showPartnerImageModal && 
                <PartnerImageModal
                    index={props.index}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                    show={showPartnerImageModal}
                    showModal={showPartnerImageModal}
                    setShowModal={setShowPartnerImageModal}
                    partnerId={props.partnerId}
                    handleDeleteImage={props.handleDeleteImage}
                    image={props.image}
                />
                }
        </Card>
    )
}
    

export default PartnerCard;