import axios from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Button, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SettingsContext from "../context/SettingsContext";
import { translate } from "../services/translation/translate";
import "./Gallery.scss";

function Gallery() {

    let navigate = useNavigate();
    const [partners, setPartners] = useState([]);
    const {settings} = useContext(SettingsContext);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_HOST}/api/partners`)
        .then((res) => {
            const response = res.data;
            setPartners(response);
        })
        .catch(err => {
            navigate("/error-500");
        });
    },[])

    return (
        partners &&
        <Carousel fade>
            {partners.map((e) => 
                e.shootingImagesUrl.length > 0 && 
                <Carousel.Item key={e._id} className="gallery position-relative" interval={3000}>
                    <img
                        className="gallery__image"
                        src={e.shootingImagesUrl[0]}
                        alt={`shooting photo ${e.name}`}
                    />
                    <div className="py-5 bg-black-opacity d-flex flex-column justify-content-center align-items-center position-absolute top-50 start-50 translate-middle w-100 gallery-container text-center text-white">
                        <div>
                            <h1 className="main-text text-uppercase px-5 pb-4">{e.name}</h1>
                            <Button 
                                variant="outline-primary text-white" 
                                className="text-white fs-5 fw-bolder mb-3"
                                onClick={() => navigate(`/gallery`)}
                            >
                                {translate.gallery.buttonText[settings.language]}
                            </Button>
                        </div>
                        {/* <div className="bg-black-opacity px-5 py-4">
                            <div className="d-flex justify-content-center fs-5 pb-4">
                                <p>{translate.gallery.partnerText[settings.language]} &nbsp;<a className="text-white" href={e.partnerUrl}><u>{translate.gallery.here[settings.language]}</u></a></p>
                            </div>
                            <div>
                                <img className="p-3 partner-page-logo" src={e.imageUrl} alt={`${e.name} logo`} />
                            </div>
                            <Button 
                                variant="outline-primary text-white" 
                                className="fw-bolder text-center"
                                onClick={() => navigate(`/gallery`)}
                            >
                                {translate.gallery.buttonText[settings.language]}
                            </Button>
                        </div> */}
                    </div>
                </Carousel.Item>
            )}
      </Carousel>
    )
}

export default Gallery