import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function ProductCard(props) {

    let navigate = useNavigate();

    return (
        <Card 
        className="m-3 position-relative bg-transparent"
        onClick={() => navigate(`/products/${props.id}`)}
        >
            <Card.Body className="text-center card-product-image m-auto p-0">
                <Card.Img className="main-product-image m-auto img-fluid" src={props.gallery[0].imageUrl} alt={`Bombers Original ${props.name}`} />
            </Card.Body>
            <Card.Title className="text-center pt-4 text-uppercase fw-bold">{props.name}</Card.Title>
        </Card>
    )
}

export default ProductCard;