import { useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SettingsContext from "../context/SettingsContext";
import { translate } from "../services/translation/translate";


function ErrorPage() {

    let navigate = useNavigate();
    const {settings} = useContext(SettingsContext);

    return (
        <div>
            <div className="bg-website"></div>
        <main className="vh-100 d-flex container align-items-center justify-content-center">
            <div className="bg-black-opacity p-5 text-white bg-opacity-75 text-center p-4">
                <h1 className="pt-3 text-uppercase fw-bold mh-0">{translate.errorPage.whoops[settings.language]}</h1>
                <p className="fs-4">{translate.errorPage.errorText1[settings.language]}<br/>{translate.errorPage.errorText2[settings.language]}</p>
                <Button 
                className="mt-4"
                variant="outline-primary text-white"
                onClick={()=> {navigate("/home", {replace: true})}}
                >
                    {translate.errorPage.buttonText[settings.language]}
                </Button>
            </div>
        </main>
    </div>
    )
}

export default ErrorPage;