import { useEffect, useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import StandardButton from "../button/StandardButton";
import AdminAccount from "./AdminAccount";
import "./AdminAccounts.scss";
import InvitationModal from "../invitation/InvitationModal";
import Invitation from "../invitation/Invitation";
import AdminData from "./AdminData";
import AlertModal from "../AlertModal";
import {displayErrorMessage, displayMessage} from "../../services/errorsTools";
import {useNavigate} from "react-router-dom";
import { hasAuthenticated } from "../../services/authTools";


function AdminAccounts() {

    const {user, setUser} = useContext(AuthContext);
    const [accounts, setAccounts] = useState([]);
    const [invitations, setInvitations] = useState([]);
    const [showInvitationModal, setShowInvitationModal] = useState(false);
    const [Message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    let navigate = useNavigate();

    function reorderedAccounts(user, data, setAccountsfunction) {
        if (user.isSuperAdmin) {
            const superAdmin = data.find((e) => e._id === user.userId)
            const index = data.findIndex((e) => e._id === user.userId);
            data.splice(index, 1)
            data.splice(0, 0, superAdmin);
        }
        setAccountsfunction(data);        
    };

    useEffect(() => {
        setUser(hasAuthenticated());
        axios.get(
            `${process.env.REACT_APP_HOST}/api/admin/accounts`,
            {headers : {"Authorization" : `Bearer ${user.token}`}}
        )
        .then((res) => {
            reorderedAccounts(user, res.data, setAccounts)
        })
        .catch(err => {
            displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
        });
        axios.get(
            `${process.env.REACT_APP_HOST}/api/admin/invitations`,
            {headers : {"Authorization" : `Bearer ${user.token}`}}
        )
        .then((res) => setInvitations(res.data))
        .catch(err => {
            displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
        });
    }, []);

    function handleUpdateAdminData(data) {
        if (hasAuthenticated()) {
            axios.put(
                `${process.env.REACT_APP_HOST}/api/admin/accounts/${data.accountId}`,
                data,
                {headers : {"Authorization" : `Bearer ${user.token}`}},
            )
            .then(res => {
                displayMessage(setShowAlert, "La modification du compte a bien été effectuée", setMessage);
                const accountIndex = accounts.findIndex((element) => element._id === res.data._id);
                accounts.splice(accountIndex, 1, res.data);
                setAccounts([...accounts]);
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    }

    function handleUpdatePassword(passwordData) {
        if (hasAuthenticated()) {
            axios.put(
                `${process.env.REACT_APP_HOST}/api/admin/accounts/${passwordData.accountId}/password`,
                passwordData,
                {headers : {"Authorization" : `Bearer ${user.token}`}},
            )
            .then((res) => {
                displayMessage(setShowAlert, "Votre mot de passe a bien été modifié", setMessage);
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }

    }

    function handleDeleteAdminAccount(accountId) {
        if (hasAuthenticated()) {
            axios.delete(
                `${process.env.REACT_APP_HOST}/api/admin/accounts/${accountId}`,
                {
                    headers : {"Authorization" : `Bearer ${user.token}`},
                    data : {userId : user.userId}
                }
            )
            .then(res => {
                displayMessage(setShowAlert,`Le compte de ${res.data} a bien été supprimée`, setMessage);
                const accountIndex = accounts.findIndex((element) => element.email === res.data);
                accounts.splice(accountIndex, 1);
                setAccounts([...accounts]);
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    }

    function handleSendInvitation(email) {
        if (hasAuthenticated()) {
            axios.post(
                `${process.env.REACT_APP_HOST}/api/admin/invitations`,
                {email : email},
                {headers : {"Authorization" : `Bearer ${user.token}`}}
            )
            .then((res) => {
                displayMessage(setShowAlert, "L'invitation a bien été envoyée", setMessage);
                invitations.push(res.data);
                setInvitations([...invitations]);
            })
            .catch(err => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    }

    function handleDeleteInvitation(invitationId) {
        if (hasAuthenticated()) {
            axios.delete(
                `${process.env.REACT_APP_HOST}/api/admin/invitations/${invitationId}`,
                {
                    headers : {"Authorization" : `Bearer ${user.token}`},
                    data : {userId : user.userId}
                }
            )
            .then((res) => {
                    displayMessage(setShowAlert, "L'invitation a bien été supprimée", setMessage);
                    const invitationIndex = invitations.findIndex((element) => element._id === res.data);
                    invitations.splice(invitationIndex);
                    setInvitations([...invitations]);
            })
            .catch((err) => {
                displayErrorMessage(setShowErrorAlert, err, setMessage, navigate);
            });
        } else {
            setUser(null);
        }
    };

    return (
        <div>
            <div className="bg"></div>
            <main className="pt-5">
                {user.isSuperAdmin &&
                    <StandardButton 
                        text="Envoyer une invitation"
                        onClick={() => setShowInvitationModal(true)}
                    />
                
                }
                {user.isSuperAdmin &&
                 <article className="container invitation-container rounded my-3 px-0">
                    <div className="bg-black rounded-top p-2 text-white">
                        <h1 className="fs-5 text-center fw-bold m-0 px-5 py-2">Invitations envoyées</h1>
                    </div>
                    {invitations.length === 0 ? 
                        <p className="fw-bolder m-auto text-center p-2">Aucune invitation en cours</p>
                    :
                    invitations.map((e) => 
                        <Invitation
                            invitationsLength={invitations.length}
                            key={e._id}
                            email={e.email}
                            invitationId={e._id}
                            handleDeleteInvitation={handleDeleteInvitation}
                        />
                    )
                    }
                </article>
                }
                {accounts.map((e) => 
                    e._id === user.userId &&
                        <AdminAccount 
                            handleUpdatePassword={handleUpdatePassword}
                            handleDeleteAdminAccount={handleDeleteAdminAccount}
                            handleUpdateAdminData={handleUpdateAdminData}
                            accountId={e._id}
                            isSuperAdmin={e.isSuperAdmin}
                            key={e._id}
                            firstName={e.firstName}
                            lastName={e.lastName}
                            email={e.email}
                            password={e.password}
                        />
                )}
                {user.isSuperAdmin &&
                <article className="container rounded my-3 px-0">
                    <div className="bg-black rounded-top p-2 text-white position-relative">
                        <h1 className="fs-5 text-center fw-bold m-0 px-5 py-2">Liste des comptes </h1>
                    </div>
                        {accounts.map((e) => 
                            e._id !== user.userId &&
                                <AdminData 
                                    handleUpdatePassword={handleUpdatePassword}
                                    handleDeleteAdminAccount={handleDeleteAdminAccount}
                                    handleUpdateAdminData={handleUpdateAdminData}
                                    accountId={e._id}
                                    isSuperAdmin={e.isSuperAdmin}
                                    key={e._id}
                                    firstName={e.firstName}
                                    lastName={e.lastName}
                                    email={e.email}
                                    password={e.password}
                                />
                        )}
                </article>
                } 

   
                {showInvitationModal &&
                    <InvitationModal
                        handleSendInvitation={handleSendInvitation}
                        showModal={showInvitationModal}
                        setShowModal={setShowInvitationModal}
                    />
                }
                {showAlert &&
                     <AlertModal
                     className="text-success px-3 fw-bold"
                     showModal={showAlert}
                     setShowModal={setShowAlert}
                     alertText={Message}
                     />
                }
                {showErrorAlert &&
                    <AlertModal
                    className="text-danger px-3 fw-bold"
                    showModal={showErrorAlert}
                    setShowModal={setShowErrorAlert}
                    alertText={Message}
                    />
                }
            </main>
        </div>
    );
}

export default AdminAccounts;
