import axios from "axios";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { useNavigate, useParams } from "react-router-dom";
import SettingsContext from "../context/SettingsContext";
import { translate } from "../services/translation/translate";
import "./Product.scss";
import {Helmet} from "react-helmet";

function Product() {

    let navigate = useNavigate();
    const { id } = useParams();
    const [product, setProduct] = useState();
    const [mainProduct, setMainProduct] = useState();
    const {settings} = useContext(SettingsContext);

    const responsiveRules = product == null ? {} : {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items:  product.gallery.length < 5 ? product.gallery.length : 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 998 },
            items:  product.gallery.length < 4 ? product.gallery.length : 4
        },
        tablet: {
            breakpoint: { max: 998, min: 576 },
            items: product.gallery.length < 3 ? product.gallery.length : 3
        },
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 1,
        }
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_HOST}/api/products/${id}`)
        .then((res) => {
            const response = res.data;
            setMainProduct(response.gallery[0].imageUrl);
            setProduct(response);
        })
        .catch(err => {
            navigate("/error-500");
        });
    },[])



    return (
        product &&
        <div>
            <Helmet>
                <title>{translate.product.headTitle[settings.language]} {product.name}</title>
                <meta name="description" content={translate.product.headDescription[settings.language]}/>
            </Helmet>
            <div className="bg-product"></div>
            <div className="main-padding-top">
                <h1 className="main-text text-center fw-bolder text-uppercase my-5 p-2">{product.name}</h1>
                <main className="bg-black-opacity text-white pt-3 pb-5">
                    <div className="ps-3 pb-3">
                        <Button 
                            variant="outline-primary text-white"
                            onClick={() => navigate(-1)}
                        >
                            {translate.product.buttonText[settings.language]}
                        </Button>
                    </div>
                    <div className="product-main-container m-auto d-flex justify-content-around">
                        <div className="row gap-3 w-100 justify-content-center align-items-center">
                            <section className="order-1 col-11 col-md-4">
                                <div className="text-center">
                                    <img className="main-product-image" src={mainProduct} alt={product.name} />
                                </div>
                            </section>
                            <aside className="order-3 order-md-2 col-11 col-md-6">
                                <div className="pb-4 d-flex flex-column justify-content-center align-items-center">
                                    <h2 className="text-nowrap fs-5 m-0 pe-3 mb-3">{translate.product.sizes[settings.language]}</h2>
                                    <div className="d-flex w-75 flex-wrap justify-content-center">
                                        {product.sizes.map((e) => 
                                            <div key={e} className="badge bg-white text-black m-2">
                                                <p className="m-0 text-bolder">{e}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="pb-3 d-flex justify-content-center align-items-center">
                                    <h2 className="fs-5 m-0">{translate.product.composition[settings.language]} : </h2>
                                    <p className="ps-3 fw-bold">{product.composition[settings.language]}</p>
                                </div>
                                <div className="pb-3 d-flex justify-content-center align-items-center">
                                    <h2 className="fs-5 m-0">{translate.product.wholeSalePrice[settings.language]} : </h2>
                                    <p className="ps-3 fw-bold">{product.wholeSalePrice} €</p>
                                </div>
                                <div className="pb-3 d-flex justify-content-center align-items-center">
                                    <h2 className="fs-5 m-0">{translate.product.retailPrice[settings.language]} : </h2>
                                    <p className="ps-3 fw-bold">{product.retailPrice} €</p>
                                </div>
                            </aside>
                            <div className="order-2 order-md-3 m-2 py-3">
                                <div className="border-bottom border-silver w-75 m-auto"></div>
                                <Carousel 
                                    responsive={responsiveRules} 
                                    className="order-2 order-md-3 m-2 py-3"
                                    swipeable={true}
                                >
                                    {product.gallery.map((e) => 
                                        <Card 
                                        key={e._id} 
                                        className="h-100 position-relative p-2 m-2 bg-transparent"
                                        >
                                            <Card.Body className="h-100 bg-opacity-25 rounded p-0">
                                                <Card.Img 
                                                    className="carousel-product-image p-2 m-auto img-fluid" 
                                                    src={e.imageUrl} 
                                                    alt={`Bombers Original ${e.name}`} 
                                                    onClick={(e) => setMainProduct(e.target.src)} 
                                                />
                                            </Card.Body>
                                            <Card.Title className="text-center pb-3 text-uppercase fw-bold">{e.color[settings.language]}</Card.Title>
                                        </Card>
                                    )}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Product;