import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "./PartnerGallery.scss";
import { translate } from "../services/translation/translate";
import SettingsContext from "../context/SettingsContext";
import {Helmet} from "react-helmet";

function PartnerGallery() {
    
    const {settings} = useContext(SettingsContext);
    let navigate = useNavigate();
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_HOST}/api/partners`)
        .then((res) => {
            const response = res.data;
            setPartners(response);
        })
        .catch(err => {
            navigate("/error-500");
        });
    },[])

    return (
        partners &&
        <main className="bg-black-opacity main-padding-top text-center" > 
            <Helmet>
                <title>{translate.partnerGallery.headTitle[settings.language]}</title>
                <meta name="description" content={translate.partnerGallery.headDescription[settings.language]}/>
            </Helmet>  
            {partners.map((partner) => 
                partner.shootingImagesUrl.length > 0 &&
                <section key={partner._id} className="mt-5">
                    <img style={{width: "150px"}} className="mt-5" src={partner.imageUrl} alt={`${partner.imageUrl} logo`}/>
                    <h1 className="main-text text-uppercase py-3">Bombers Original &reg;</h1>
                    <div className="w-100 py-2">
                        <div className="d-flex justify-content-center text-white fs-5 pb-4">
                            <Button variant="outline-primary"><a className="text-white" href={partner.partnerUrl}>{translate.gallery.partnerText[settings.language]}</a></Button>
                        </div>
                        <div className="row w-100 gap-5 m-0 py-5 justify-content-center">
                            {partner.shootingImagesUrl.map((e) => 
                            <div key={e} className="col-10 col-md-5 col-lg-3">
                                <Image className="partner-card-image border-black" src={e} alt={`Shooting du ${partner.name}`}/>
                            </div>
                            )}
                        </div>
                    </div>
                </section>
            )}
            
        </main>
    )
}

export default PartnerGallery;
