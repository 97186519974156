const errors =  {
    // Admin authentication errors
    INVALID_EMAIL : {
        fr : "E-mail invalide",
        en : "Invalid email"
    },
    WRONG_EMAIL : {
        fr : "Utilisateur introuvable",
        en : "User not found"
    },
    KNOWN_EMAIL : {
        fr : "Cet e-mail est déjà utilisé",
        en : "This email is already used"
    },
    WEAK_PASSWORD : {
        fr : "Le mot de passe doit contenir 8 caractères dont au minimum 1 miniscule, 1 majuscule, 1 caractère spécial et 3 chiffres",
        en : "Password must be at least 8 characters long and includes 1 lowercase, 1 uppercase, 1 special characters and 3 digits"       
    },
    WRONG_PASSWORD : {
        fr : "Mot de passe incorrect",
        en : "Wrong password"       
    },
    // Admin request errors
    AUTH_ERROR : {
        fr : "Vous n'avez pas les droits pour effectuer cette requête",
        en : "You don't have the rights to perform this request"       
    },
    AUTH_TOKEN_ERROR : {
        fr : "Les identifiants de connexion reçues sont invalides",
        en : "Authentication data received are invalid"
    },
    SUPERADMIN_DELETION_ERROR : {
        fr : "Ce compte ne peut être supprimé",
        en : "This account cant't be deleted"
    },
    UPDATE_ADMIN_DATA_ERROR : {
        fr : "Seul le propriétaire du compte ou l'administrateur principale peuvent modifier les données d'un compte",
        en : "Only owner of this account or superAdmin can update accounts data"
    },
    UPDATE_ADMIN_PASSWORD_ERROR : {
        fr : "Seul le propriétaire du compte peut modifier son mot de passe",
        en : "Only owner of this account  can update his password"
    },
    ADMIN_NOT_FOUND_ERROR : {
        fr : "Utilisateur(s) introuvables",
        en : "User(s) not found"
    },
    DELETE_ADMIN_ERROR : {
        fr : "Une erreur est survenue lors de la suppression du compte",
        en : "An error occured during the deletion of the account"
    },
    // Invitation signup errors
    INVITATION_ERROR : {
        fr: "Vous ne pouvez créer un compte car votre invitation est introuvable",
        en: "You can't create an account since your invitation hasn't been found"
    },
    INVITATION_NOT_FOUND_ERROR : {
        fr: "L'invitation a expiré",
        en: "Invitation has expired"
    },
    INVITATION_EMAIL_FOUND_ERROR : {
        fr: "Cet email est déjà relié à un compte",
        en: "This email is already linked to an account"
    },
    // NODEMAILER_ERROR
    NODEMAILER_ERROR : {
        fr : "L'invitation n'a pas pu être envoyé",
        en : "The invitation hasn't been send"
    },
    // MONGOOSE 
    MONGOOSE_UNIQUE_EMAIL_ERROR : {
        fr: "Une invitation a déja été envoyé à cette adresse mail",
        en: "An invitation has been already sent to this email"
    },
    MONGOOSE_VALIDATIONERROR : {
        fr : "La requête n'a pu aboutir car des données sont manquantes",
        en : "Request could not success since required data are missing"
    },
    // FILES AND FIELD
    MISSING_FILE_ERROR : {
        fr : "Cette requête nécessite de renseigner une image",
        en : "This request required a image file"
    },
    MISSING_FILED_ERROR : {
        fr : "La requête n'a pu aboutir car des données sont manquantes",
        en : "Request could not success since required data are missing"
    },
    // PARTNERS 
    PARTNER_NOT_FOUND_ERROR : {
        fr : "Partenaire(s) introuvable(s)",
        en : "Partner(s) not found"
    },
    // PRODUCTS
    MALFORMED_REQUEST_ERROR : {
        fr : "La requête ne peut aboutir car les données sont mal formatées",
        en : "Request can't occured since data are malformated"
    },
    PRODUCT_NOT_FOUND : {
        fr : "Produit(s) introuvable(s)",
        en : "Product(s) not found"
    }

}

export function displayError(error, navigate) {
    
    if (error.response.status === 500) {
        navigate("/admin/error-500");
    }

    if (error.response.status !== 500) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return errors[error.response.data.error].fr;
    }
    
    if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return `Le server est injoignable: ${error.request}`;
    }

    // Something happened in setting up the request that triggered an Error
    return `Une erreur est survenue: ${error.message}`
};  

export function displayErrorMessage(setShowErrorAlert, err, setMessage, navigate) {
    setShowErrorAlert(true);
    setMessage(displayError(err, navigate));
    setTimeout( () => {setShowErrorAlert(false); setMessage("")}, 1500);
};

export function displayMessage(setShowAlert, message, setMessage) {
    setShowAlert(true);
    setMessage(message);
    setTimeout( () => {setShowAlert(false); setMessage("")}, 1500);
};
