
export function handleProgress(regexes, value) {
    let color = "danger";
    let score = 0;

    for (let regex of regexes) {
        if (regex.test(value)) {
            score++;
        }
    }
    const progress = (100 * score / regexes.length);
    if (progress === 100) {
        color = "success";
    }
    else if (progress >= 50) {
        color = "warning";
    }
    return {progress : progress, color : color};
};