import Cookies from "js-cookie";


export function setCookie(keyName, item, value) {
    Cookies.set(keyName, JSON.stringify(item), {expires: value})
};

export function getCookie(keyNameToGet) {
    const cookies = Cookies.get(keyNameToGet);
    if (cookies) {
        return JSON.parse(cookies);
    }
    return null;
};

export function removeCookie(KeyNameToDelete) {
    Cookies.remove(KeyNameToDelete)
};