export const headerTranslation = {
    home : {
        fr : "Accueil",
        en : "Home"
    },
    products : {
        fr : "Produits",
        en : "Products"
    },
    allProducts : {
        fr : "Voir tous les produits",
        en : "See all products"
    },
    gallery : {
        fr : "Galerie des partenaires",
        en : "Partners gallery"
    },
    contact : {
        fr : "Contactez-nous",
        en : "contact us"
    },
    language : {
        fr : "Langue",
        en : "Language"
    },
    alt : {
        fr : "Bombers original logo",
        en : "Bombers original logo"
    }
}
