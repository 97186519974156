import { useState } from "react";
import { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import {BsThreeDots} from "react-icons/bs";
import AuthContext from "../../context/AuthContext";
import DeleteAlert from "../DeleteAlert";
import AlertModal from "../AlertModal";
import PasswordModal from "../PasswordModal";
import "./AdminAccount.scss";
import AdminAccountModal from "./AdminAccountModal";



function AdminAccount(props) {
    const {user} = useContext(AuthContext);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    return (
        <article className="container account-container rounded mt-5 mb-5 px-0">
            <div className="bg-black rounded-top p-2 text-white position-relative">
                    <h1 className="fs-5 text-center fw-bold m-0 px-5 py-2">Votre compte</h1>
            </div>
            {showAlert && 
            <div className="p-3">
                <DeleteAlert 
                    onClose={() => setShowAlert(false)}
                    onClick={() => props.handleDeleteAdminAccount(props.accountId)}
                    text={`Etes-vous sur de vouloir supprimer ce compte ?`}
                />
            </div>
            }
            <div className="row m-0 p-2 text-break">
                <div className="col">
                    <div className="pb-2 col-auto fw-bolder">
                        <p>{props.firstName} {props.lastName}</p>
                    </div>
                    <div className="col-auto pb-2 fw-bolder">
                        <p>{props.email}</p>
                    </div>
                </div>
                <div className="col-2 text-center justify-content-center">
                    <Dropdown
                        drop="up"
                        align="end"
                    >
                        <Dropdown.Toggle className="bg-transparent shadow-none border-light p-0 m-0">
                            <BsThreeDots size={24} color="black" aria-label="ouvrir le menu à developper"/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            className="dropdown-product-menu"
                        >
                            <Dropdown.Item 
                                className="dropdown-product-menu__item"
                                onClick={() => setShowAccountModal(true)}
                            >
                                Modifier
                            </Dropdown.Item>
                            {user.userId === props.accountId &&
                                <Dropdown.Item 
                                    className="dropdown-product-menu__item"
                                    onClick={() => setShowPasswordModal(true)}
                                >
                                    Changer de mot de passe
                                </Dropdown.Item>
                            }
                            {showPasswordModal && 
                                <PasswordModal
                                handleUpdatePassword={props.handleUpdatePassword}
                                showModal={showPasswordModal}
                                setShowModal={setShowPasswordModal}
                                accountId={props.accountId}
                                />
                            }
                            {props.showPasswordChangedModal &&
                                <AlertModal 
                                showModal={props.showPasswordChangedModal}
                                setShowModal={props.setShowPasswordChangedModal}
                                alertText="Votre mot de pass à bien été modifié"
                                />
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {showAccountModal && 
                <AdminAccountModal 
                    handleUpdateAdminData={props.handleUpdateAdminData}
                    accountId={props.accountId}
                    showModal={showAccountModal}
                    setShowModal={setShowAccountModal}
                    firstName={props.firstName}
                    lastName={props.lastName}
                    email={props.email}
                    buttonText="Enregistrer les modifications"
                /> 
            }
        </article>
    );
}

export default AdminAccount;