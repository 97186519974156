export const homeTranslation = {
    headTitle : {
        fr : "Bombers Original Partenariats - Accueil",
        en : "Bombers Original Partnerships - Home"
    },
    headDescription : {
        fr : "Bombers Original Partenariats vous propose des bombers Original aux couleurs de votre club ou de votre entreprise. Nous développons des Bombers Original personnalisés à destination de vos clients, membres, supporters et collaborateurs.", 
        en : "Bombers Original Partnerships offer Original Bombers in the colors of your Club or Company. We developp cutomized Bombers Original designed for your supporters, clients, members, collaborators"
    },
    title1 : {
        fr : "Bombers Original",
        en : "Bombers Original"
    },
    title2 : {
        fr : "partenariats",
        en : "partnerships"
    },
    legendaryBrand : {
        fr : "Une MARQUE mythique",
        en : "A legendary BRAND"
    },
    phrase1 : {
        fr : "Un BOMBERS ORIGINAL",
        en : "An iconic customized BOMBERS ORIGINAL"
    },
    phrase2 : {
        fr : "iconique personnalisé à vos couleurs…",
        en : " in the colors of your Club or Company…"
    },
    phrase3 : {
        fr : "À destination de vos supporters, clients, membres, collaborateurs",
        en : "Designed for your supporters, clients, members, collaborators"
    },
    contact : {
        fr : "Vous souhaitez devenir l'un de nos futurs partenaires ?",
        en : "Would you like to become one of our future partners ?"
    },
    contactButton : {
        fr : "Contactez-nous",
        en : "Contact us"
    },
    products : {
        fr : "Nos best sellers",
        en : "Our best sellers"
    },
    partners : {
        fr : "Nos partenaires",
        en : "Our partners"
    },
    productText1 : {
        fr : "Nous proposons une large gamme de produits personnalisables",
        en : "We have a large range of customizable items"
    },
    productText2 : {
        fr : "Voici quelques uns de nos best sellers",
        en : "Here are some of our lead best sellers"
    },
    productButtonText : {
        fr : "Voir tous les produits",
        en: "See all products"
    },
    partnersText : {
        fr : "Vous aussi rejoignez l'expérience et devenez l'un de nos nombreux partenaires",
        en : "You can also join the experience and become one of our numerous partners"
    },
    partnersButtonText : {
        fr : "Voir la galerie des partenaires",
        en : "See the partners gallery"
    }

}
