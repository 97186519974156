import AdminHeader from "../../header/AdminHeader";
import serverError from "../../../assets/error-server.png";
import StandardButton from "../../button/StandardButton";
import { useNavigate } from "react-router-dom";
import "./ErrorPage.scss";

function ServerErrorPage() {

    let navigate = useNavigate();

    return (
        <div className="bg ">
            <main className="vh-100 d-flex container align-items-center justify-content-center">
                <div className="bg-light p-3 rounded bg-opacity-75 text-center p-4">
                    <img className="error-img" src={serverError} alt="error 500 logo" />
                    <h1 className="pt-3 text-uppercase fw-bold mh-0">internal server error</h1>
                    <p className="fs-4">Une erreur serveur est survenue</p>
                    <StandardButton 
                    variant="black"
                    text="retour"
                    onClick={()=> {navigate(-1)}}
                    />
                </div>
            </main>
        </div>
    )
}

export default ServerErrorPage;