import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SettingsContext from "../context/SettingsContext";
import { translate } from "../services/translation/translate";
import ProductCard from "./ProductCard";
import {Helmet} from "react-helmet";

function Products() {

    let navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const {settings} = useContext(SettingsContext);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_HOST}/api/products`)
       .then((res) => {
           const response = res.data;
           setProducts(response);
       })
       .catch((err) => {
           navigate("/error-500");
       });
    },[])

    return (
        <div>
            <Helmet>
                <title>{translate.products.headTitle[settings.language]}</title>
                <meta name="description" content={translate.products.headDescription[settings.language]}/>
            </Helmet>
            <div className="bg-products"></div>
            <div className="main-padding-top">
                <h1 className="main-text text-center fw-bolder text-uppercase my-5 p-2">{translate.products.productsText[settings.language]}</h1>
                <main className="bg-black-opacity text-white py-5 d-flex justify-content-center">
                    <div className="row w-75 gap-2">
                    {products.map((e) => 
                        <div key={e._id} className="col-10 col-md-5 col-lg-3 justify-content-center m-auto">
                            <ProductCard
                            name={e.name}
                            gallery={e.gallery}
                            id={e._id}
                            />
                        </div>
                    )}

                    </div>
                </main>
            </div>
        </div>
    )
}

export default Products;